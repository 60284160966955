export enum TableFieldType {
  text,
  number,
  password,
  email,
  color,
  date,
  yesNo,
  select,
}

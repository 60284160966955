import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgEventBus } from 'ng-event-bus';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
    selector: 'app-cropper-photo-form',
    templateUrl: './cropper-photo-form.component.html',
    styleUrls: ['./cropper-photo-form.component.css'],
    providers: []
})
export class CropperPhotoComponentForm implements OnInit {
    public readonly BASE_TRANSLATE_SHARED = "shared";
    cargaservice: boolean = false;
    loading: boolean = false;

    is_paciente: boolean = false;

    listSex: any[] = [];

    imageChangedEvent: any = '';
    croppedImage: any = '';

    image: any = "assets/img/avatar.png";

    containWithinAspectRatio = false;
    canvasRotation = 0;
    transform: ImageTransform = {};
    showCropper: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<CropperPhotoComponentForm>,
        private eventBus: NgEventBus
    ) { }

    ngOnInit() {
        // code
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        this.showCropper = true;
    }

    submit() {
        this.eventBus.cast("avatar:form:user", { avatar: this.croppedImage });
        this.dialogRef.close(true);
    }
}
<div class="wrapper-title-app" mat-dialog-title>
  <button type="button" class="btn-app" (click)="closeModalDialog()"><span class="fas fa-arrow-left"></span></button>
  <p class="title-app">{{ BASE_TRANSLATE + '.text_enviar_a' | translate }} {{ username }} </p> <az-loader-button
    [show-loading]="cargaservice" [disabled]="cargaservice" class="mt-2"></az-loader-button>
</div>
<div mat-dialog-content>
  <div *ngIf="!loading" class="chat-app">
    <div #chatmsg class="chat-app--msg">
      <div *ngIf="page < lastPage && listMessage.length <= totalItem" class="more-message">
        <button (click)="getMoreMessage()" [disabled]="cargaservice" class="btn"> {{
          'dashboard.widget_cars_programas.text_eye' | translate }} </button>
      </div>
      <div *ngFor="let m of listMessage; let i = index" class="msg" [ngClass]="m.i_sent_it === true ? 'right' : 'left'">
        <ng-container *ngIf="m.id_type_sms == 1;">
          <p class="descp">{{ m.sms_chat }}</p>
        </ng-container>
        <ng-container *ngIf="m.id_type_sms == 2;">
          <p class="descp">📷 Foto</p>
        </ng-container>
        <ng-container *ngIf="m.id_type_sms == 3;">
          <p class="descp">🎵 Audio</p>
        </ng-container>



        <p class="fecha">{{ m.created_at }}</p>
        <span *ngIf="m.i_sent_it === true" class="icon-readed"
          [ngClass]="m.is_read ? 'fa fa-check-circle' : 'far fa-check-circle'"></span>
      </div>
    </div>
    <div [formGroup]="ChatFrom" class="chat-app--input">
      <div class="input-mensaje">
        <textarea id="msg" [placeholder]="BASE_TRANSLATE + '.form.input_mensaje' | translate" formControlName="msg"
          class="chat-input" (keyup.enter)="ChatFrom.valid ? sendSMS() : ''"></textarea>
      </div>
      <button type="button" [disabled]="!ChatFrom.valid" (click)="ChatFrom.valid ? sendSMS() : ''"
        class="chat-btn"><span class="icon-send fas fa-paper-plane"></span></button>
    </div>
    <div class="chat-app--footer">
      <div class="recibir-message">
        <label class="text">
          <div class="toggle-app" (click)="canAnswer()" [ngClass]="can_answer ? 'checked' : ''">
            <div class="toggle-app__circle"></div>
          </div>
          {{ BASE_TRANSLATE + (can_answer ? '.text_beneficiario_responde' : '.text_beneficiario_no_responde') |
          translate }}
        </label>
      </div>
    </div>
  </div>
  <div *ngIf="loading" [ngStyle]="{width: '500px', height: '70vh'}" class="fcc">
    <az-loader-base [show-loading]="loading"></az-loader-base>
  </div>
  <az-loader-fullscreen [show-loading]="cargaservicefull"></az-loader-fullscreen>
</div>
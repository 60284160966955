<i class="fas fa-times cerrar-modal" (click)="this.dialogRef.close(false);"></i>
<h4 mat-dialog-title class="titulo-modal">{{ BASE_TRANSLATE + '.title_cropper_photo' | translate }}</h4>
<mat-dialog-content style="width: 700px !important;">
	<div class="mdl-grid">
		<div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
			<div class="button-send-image">
				<input #openimage type="file" accept="image/jpg, image/png, image/gif, image/jpeg" class="no-display"
					(change)="fileChangeEvent($event)">
				<button (click)="openimage.click()" type="button"
					class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent download-button">Subir
					imagen</button>
				<p>Peso máximo 500kb</p>
			</div>
		</div>
		<div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
			<image-cropper class="image-cropper" *ngIf="imageChangedEvent != ''" style="height: 350px;"
				[imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
				[containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="5 / 5" [alignImage]="'center'"
				format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"></image-cropper>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button (click)="dialogRef.close()" type="button" class="mdl-button mdl-button-cancel close-modal modal__close">{{
		BASE_TRANSLATE_SHARED + '.buttons.cancel' | translate}}</button>
	<button [disabled]="!croppedImage || cargaservice" type="button" (click)="croppedImage != '' ? submit() : ''" class="mdl-button close-modal modal__close">{{
		BASE_TRANSLATE_SHARED + '.buttons.save' | translate }} <az-loader-button [show-loading]="cargaservice"
			[color]="'var(--primario)'" [disabled]="!croppedImage || cargaservice ? true : false"></az-loader-button>
	</button>
</mat-dialog-actions>
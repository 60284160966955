import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { KeycloakSecurityService } from './keycloak-security.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedKeycloak {
    private url = environment.URLKeyKloack;
    private url2 = environment.URL_LAMBDA;
    public userProfile: any;

    roles: any[] = [];
    is_superadmin: Observable<boolean> = of(false);
    is_propietario: Observable<boolean> = of(false);
    is_admin: Observable<boolean> = of(false);
    is_profesional: Observable<boolean> = of(false);
    is_asistente: Observable<boolean> = of(false);
    is_paciente: Observable<boolean> = of(false);
    is_upi: Observable<boolean> = of(false);

    constructor(protected _http: HttpClient, public securityService: KeycloakSecurityService) {
        localStorage.setItem(`azh_${environment.name}_roles`, JSON.stringify(securityService.kc.realmAccess.roles));
        this.roles = localStorage.getItem(`azh_${environment.name}_roles_from_support`) ? JSON.parse(localStorage.getItem(`azh_${environment.name}_roles_from_support`)) : this.securityService.kc.tokenParsed["me"].perfil;

        this.isSuperAdmin();
        this.isPropietario();
        this.isAdmin();
        this.isProfesional();
        this.isAsistente();
        this.isUpi();

        if (this.roles.length <= 1) {
            this.roles.forEach(rol => {
                let value: String = typeof rol == 'object' ? rol.nombre : rol
                if (value.toLowerCase() === 'paciente') {
                    this.isPaciente();
                }
            });
        }
    }

    public isSuperAdmin(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.roles.forEach(rol => {
                let value: String = typeof rol == 'object' ? rol.nombre : rol
                if (value.toLowerCase() === "super_admin") {
                    this.is_superadmin = of(true);
                    resolve(true);
                }
            });

            resolve(false);
        });
    }
    
    public isUpi(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.roles.forEach(rol => {
                let value: String = typeof rol == 'object' ? rol.nombre : rol
                if (value.toLowerCase() === "upi") {
                    this.is_upi = of(true);
                    resolve(true);
                }
            });

            resolve(false);
        });
    }

    public isPropietario(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.roles.forEach(rol => {
                let value: String = typeof rol == 'object' ? rol.nombre : rol
                if (value.toLowerCase() === "propietario") {
                    this.is_propietario = of(true);
                    resolve(true);
                }
            });

            resolve(false);
        });
    }

    public isAdmin(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.roles.forEach(rol => {
                let value: String = typeof rol == 'object' ? rol.nombre : rol
                if (value.toLowerCase() === "admin") {
                    this.is_admin = of(true);
                    resolve(true);
                }
            });

            resolve(false);
        });
    }

    public isProfesional(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.roles.forEach(rol => {
                let value: String = typeof rol == 'object' ? rol.nombre : rol
                if (value.toLowerCase() === "profesional") {
                    this.is_profesional = of(true);
                    resolve(true);
                }
            });

            resolve(false);
        });
    }

    public isAsistente(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.roles.forEach(rol => {
                let value: String = typeof rol == 'object' ? rol.nombre : rol
                if (value.toLowerCase() === "asistente") {
                    this.is_asistente = of(true);
                    resolve(true);
                }
            });

            resolve(false);
        });
    }

    public isPaciente(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.roles.forEach(rol => {
                let value: String = typeof rol == 'object' ? rol.nombre : rol
                if (value.toLowerCase() === "paciente") {
                    this.is_paciente = of(true);
                    resolve(true);
                }
            });

            resolve(false);
        });
    }

    public getTokenInfoServices(id_token) {
        let accessToken = id_token;
        const headerSettings: { [name: string]: string | string[] } = {};
        headerSettings['authorization'] = 'Bearer ' + accessToken;
        let serviceURL = `${this.url}realms/az-health/protocol/openid-connect/userinfo`;
        return this._http.get(serviceURL, { headers: headerSettings });
    }

    public getImageClinica() {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.get(`${this.url2}services.cliente/getlogo`, { headers: headers });
    }

    public ValidarPasswordOld(iduser: number, pass: string) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.get(`${this.url2}services.acciones/validpassold/${iduser}/${pass}`, { headers: headers });
    }

    public ChangePass(data: any) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        return this._http.post(`${this.url2}services.acciones/changepassword/`, data, { headers: headers });
    }

    public getProfileMe(): Promise<any> {
        let token = localStorage.getItem(`azh_${environment.name}_token__from_support`) || this.securityService.kc.token;
        return new Promise((resolve, reject) => {
            this.getTokenInfoServices(token).subscribe(
                (result: any) => {
                    if (result) {
                        let data = result.me;
                        resolve(data);
                    }
                }, error => {
                    reject(error);
                }
            );
        });
    }

    public logout() {
        let URI = `${window.location.protocol}//${window.location.host}/inicio`;

        if (window.location.protocol === 'https:') {
            URI = `${window.location.protocol}//${window.location.host}/inicio`;
        }
        localStorage.removeItem(`azh_${environment.name}_token__from_support`);
        localStorage.removeItem(`azh_${environment.name}_roles_from_support`);

        this.securityService.kc.logout({ redirectUri: URI });
    }
}

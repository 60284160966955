<p class="title bold" [class]="((loading && (loading_metrica || idPaciente === undefined))? 'az-skeleton' : '')">
    {{ getTitle() }}
    <span az-tooltip class="icon-info tooltip-dark fas fa-info-circle" *ngIf="idPaciente === undefined">
        <az-tooltip
            [azTooltipText]="(BASE_TRANSLATE + '.widget_cars_programas.text_tooltip_adherencia_general' | translate)"
            azTooltipPosition="top"></az-tooltip>
    </span>
</p>

<div class="graph-circle">
    <div class="item" *ngIf="idPaciente != undefined" [class]="loading_metrica ? 'az-skeleton az-skeleton-x-3 mh-48' : ''">
        <p class="text">{{info?.metrica}}</p>
    </div>
    <div class="item" [ngClass]="idPaciente === undefined ? 'w50':''" [class]="loading ? 'az-skeleton mh-48 az-skeleton-x-' + ((idPaciente === undefined)?'2':'3') : getClassColor()">
        <p class="text">{{ info?.general }}%</p>
        <p class="text">{{ BASE_TRANSLATE + '.widget_cars_programas.text_actual' | translate }}</p>
    </div>
    <div class="item text-in" [ngClass]="idPaciente === undefined ? 'w50':''" [class]="loading ? 'az-skeleton mh-48 az-skeleton-x-' + ((idPaciente === undefined)?'2':'3') : ''">
        <p class="text">{{ info?.esperada }}%</p>
        <p class="text">{{ BASE_TRANSLATE + '.widget_cars_programas.text_esperado' | translate }}</p>
    </div>
</div>
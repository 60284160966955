<i class="fas fa-times cerrar-modal" (click)="this.dialogRef.close(false);"></i>
<h4 mat-dialog-title class="titulo-modal">{{ BASE_TRANSLATE + '.title' | translate }}</h4>
<form [formGroup]="form" class="form" style="width: 500px !important;">
  <mat-dialog-content>
    <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
            <div class="mdl-grid">
                <div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
                    <mat-form-field>
                        <input matInput [type]="passold_icon ? 'password' : 'text'" formControlName="passold" [placeholder]="BASE_TRANSLATE + '.form.input_pass_actual' | translate" required minlength="4" (keyup.enter)="verificarpass(1) ? validarPasswordOld() : ''" maxlength="20"> 
                        <button type="button" mat-icon-button matSuffix (click)="passold_icon = !passold_icon" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passold_icon">
                            <mat-icon>{{ passold_icon ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                        <mat-hint *ngIf="passold_error" style="display: block; color: red !important;" class="invalid-feedback">{{ passold_msg_error }}</mat-hint>
                    </mat-form-field>
                </div>
                <div *ngIf="VALID_PASS_OLD" class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
                    <mat-form-field>
                        <input matInput [type]="passnew_icon ? 'password' : 'text'" formControlName="passnew" [placeholder]="BASE_TRANSLATE + '.form.input_pass_new' | translate" required minlength="4" maxlength="20">
                        <button type="button" mat-icon-button matSuffix (click)="passnew_icon = !passnew_icon" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passnew_icon">
                            <mat-icon>{{ passnew_icon ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div *ngIf="VALID_PASS_OLD" class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
                    <mat-form-field>
                        <input matInput [type]="passconfirm_icon ? 'password' : 'text'" formControlName="passconfirm" [placeholder]="BASE_TRANSLATE + '.form.input_pass_new_confirm' | translate" [ngClass]="{ 'is-invalid': passconfirm_msg_error }" required (keyup.enter)="!verificarpass(2) && form.valid ? change_pass() : ''" minlength="4" maxlength="20">
                        <button type="button" mat-icon-button matSuffix (click)="passconfirm_icon = !passconfirm_icon" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passconfirm_icon">
                            <mat-icon>{{ passconfirm_icon ? 'visibility_off' : 'visibility' }}</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <div *ngIf="passconfirm_error" class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
                    <mat-hint style="display: block; color: red !important;" class="invalid-feedback">{{ passconfirm_msg_error }}</mat-hint>
                </div>
            </div>
        </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button (click)="dialogRef.close()" type="button"
    class="mdl-button mdl-button-cancel close-modal modal__close">{{ BASE_TRANSLATE_SHARED + '.buttons.cancel' | translate}}</button>
    <button *ngIf="btn_show_valid === true" [disabled]="!verificarpass(1) || cargaservice" (click)="validarPasswordOld()" type="button" class="mdl-button close-modal modal__close">{{ BASE_TRANSLATE + '.form.btn_verificar' | translate }} <az-loader-button [show-loading]="cargaservice" [color]="'var(--primario)'" [disabled]="!verificarpass(1) || cargaservice ? true : false"></az-loader-button></button>
    <button *ngIf="btn_show_valid === false" [disabled]="!form.valid || verificarpass(2) || cargaservice2" type="button" (click)="change_pass()" class="mdl-button close-modal modal__close">{{ BASE_TRANSLATE + '.form.btn_change' | translate }} <az-loader-button [show-loading]="cargaservice2" [color]="'var(--primario)'" [disabled]="!form.valid || verificarpass(2) || cargaservice2 ? true : false"></az-loader-button></button>

    <!-- <button *ngIf="" style="display: block; width: max-content; margin: 1rem auto;" type="button" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent download-button" (click)="validarPasswordOld()">{{ BASE_TRANSLATE + '.form.btn_verificar' | translate }}</button>
    <button *ngIf="this.btn_show_valid === false" type="button" (click)="change_pass()" style="display: block; width: max-content; margin: 1rem auto;" [disabled]="verificarpass(2)" type="submit" class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent download-button">{{ BASE_TRANSLATE + '.form.btn_change' | translate }}</button> -->
  </mat-dialog-actions>
</form>

<ng-container [ngSwitch]="fieldType">
  <ng-container *ngSwitchCase="TABLE_FIELD_TYPE.number">
    <input type="number" [(ngModel)]="vInput" [maxlength]="getMaxlength(field)"  class="form-control campo-value" [required]="inputRequired" [disabled]="disabled" [mask]="getInputMask(field)"/>
    <p *ngIf="vInput === '' && inputRequired === true" class="sms_error text-danger">El campo no puede estar vacío</p>
  </ng-container>
  <ng-container *ngSwitchCase="TABLE_FIELD_TYPE.text">
    <input type="text" [(ngModel)]="vInput" [maxlength]="getMaxlength(field)"  class="form-control campo-value" [required]="inputRequired" [disabled]="disabled" [mask]="getInputMask(field)"/>
    <p *ngIf="vInput === '' && inputRequired === true" class="sms_error text-danger">El campo no puede estar vacío</p>
  </ng-container>
  <ng-container *ngSwitchCase="TABLE_FIELD_TYPE.password">
    <input type="password" [(ngModel)]="vInput" [maxlength]="getMaxlength(field)" class="form-control campo-value" [required]="inputRequired" [disabled]="disabled" />
    <p *ngIf="vInput === '' && inputRequired === true" class="sms_error text-danger">El campo no puede estar vacío</p>
  </ng-container>
  <ng-container *ngSwitchCase="TABLE_FIELD_TYPE.email">
    <input type="email" [(ngModel)]="vInput" [maxlength]="getMaxlength(field)" class="form-control campo-value" [required]="inputRequired" [disabled]="disabled" />
    <p *ngIf="vInput === '' && inputRequired === true" class="sms_error text-danger">El campo no puede estar vacío</p>
  </ng-container>
  <ng-container *ngSwitchCase="TABLE_FIELD_TYPE.color">
    <input type="color" [(ngModel)]="vInput" class="form-control campo-value" [required]="inputRequired" [disabled]="disabled" />
    <p *ngIf="vInput === '' && inputRequired === true" class="sms_error text-danger">El campo no puede estar vacío</p>
  </ng-container>
  <ng-container *ngSwitchCase="TABLE_FIELD_TYPE.date">
    <az-datepicker [innerValue]="vInput" (change)="valueChange($event)" [disabled]="disabled"></az-datepicker>
    <p *ngIf="vInput === '' && inputRequired === true" class="sms_error text-danger">El campo no puede estar vacío</p>
  </ng-container>
  <ng-container *ngSwitchCase="TABLE_FIELD_TYPE.yesNo">
    <select class="form-control" [required]="inputRequired" [disabled]="disabled">
      <option value="false">{{ BASE_TRANSALATE + '.tableFieldTypeYesNo.yes' | translate }}</option>
      <option value="true">{{ BASE_TRANSALATE + '.tableFieldTypeYesNo.no' | translate }}</option>
    </select>
    <p *ngIf="vInput === '' && inputRequired === true" class="sms_error text-danger">El campo no puede estar vacío</p>
  </ng-container>
  <ng-container *ngSwitchCase="TABLE_FIELD_TYPE.select">
    <select class="form-control" (change)="ngChageSelect($event)" [required]="inputRequired" [disabled]="disabled">
      <option *ngIf="inputRequired === true" value="0" disabled selected>Seleccione...</option>
      <option *ngIf="inputRequired === false" value="0" selected>Seleccione...</option>
      <option *ngFor="let v of vInput; let i = index" [value]="getIdItemSelect(v)" [selected]="getSelected(v)">{{ getLabelSelect(v) }}</option>
    </select>
    <p *ngIf="vInput === '' && inputRequired === true" class="sms_error text-danger">El campo no puede estar vacío</p>
  </ng-container>
</ng-container>

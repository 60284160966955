import { isString, get } from 'lodash';

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';

import { TableField } from '../../models/table-field';
import { AzTableService } from '../services/az-table.service';
import { TableSortEvent } from '../../models/table-sort-event';
import { TableSortDirection } from '../../models/table-sort-direction.enum';

@Component({
  selector: '[az-table-header]',
  templateUrl: './az-table-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AzTableHeaderComponent implements OnInit {
  public isAllSelected: boolean = false;

  @Output() public readonly selectAll = new EventEmitter<boolean>();
  @Output() public readonly sortChanged = new EventEmitter<TableSortEvent>();

  public readonly BASE_TRANSALATE = 'shared.table';

  @Input() public selectedAll: boolean = false;

  @Input() public actionable: boolean = false;
  @Input() public scrollable: boolean = false;

  @Input() public fields: Array<string | TableField> | null = null;
  @Input() public headerTemplate!: TemplateRef<any>;
  @Input() public selectable: boolean = false;
  @Input() selectMode: string = "multiple";
  @ViewChildren('sortIcon', { read: ElementRef }) sortIcons!: QueryList<ElementRef>;

  constructor(private tableService: AzTableService) { }

  public pxMenos: number = 0;
  public widthMenos: number = 0;
  ngOnInit(): void {
    this.calcWidthTableTH();
  }

  calcWidthTableTH() {
    let cW = 0;
    this.pxMenos = this.pxMenos + (this.selectable ? 50 : 0);
    this.pxMenos = this.pxMenos + (this.actionable ? 200 : 0);

    if (this.fields) {
      this.fields.forEach((c: any) => {
        c.sortable = c.sortable != undefined ? c.sortable : true;

        if (c.width) {
          cW++;
          this.widthMenos = cW;
          this.pxMenos = this.pxMenos + parseInt(c.width.replace('px', ''));
        }
      });
    }
  }

  public fieldTitle(field: string | TableField): string {
    let result = '';

    if (this.tableService.isTableField(field)) {
      result = field.translation ? field.translation : field.label;
    }

    return result;
  }

  public getKeyByField(field: string | TableField): string {
    if (this.isTableField(field)) {
      return field.key;
    }
    return field;
  }

  public getCellClass(field: string | TableField): string | Array<string> {
    let result: Array<any> = [];
    if (this.tableService.isTableField(field)) {
      if (field.class) {
        result.push(...(isString(field.class) ? [field.class] : field.class));
      }
      if (field.thClass) {
        result.push(...(isString(field.thClass) ? [field.thClass] : field.thClass));
      }
    }
    return result;
  }

  public getStyles(field: string | TableField) {
    let widthX = get(field, 'width', '');
    let textAlignX = get(field, 'textAlign', '');
    let lengthField = 0;
    let obj = {
      width: '',
      textAlign: ''
    };

    if (this.fields) {
      lengthField = this.fields.length;
    }

    if (widthX != '') {
      obj.width = widthX ? widthX : 'calc(' + 100 / (lengthField - this.widthMenos) + '% - ' + this.pxMenos + 'px)';
    }

    if (textAlignX != '') {
      obj.textAlign = textAlignX;
    }

    return obj;
  }

  public getStylesFlexbox(field: string | TableField) {
    let textAlignX = get(field, 'textAlign', '');
    let obj = {
      justifyContent: ''
    };

    if (textAlignX === "left") {
      obj.justifyContent = 'flex-start';
    } else if (textAlignX === 'right') {
      obj.justifyContent = 'flex-end';
    } else if (textAlignX === 'center') {
      obj.justifyContent = 'center';
    } else {
      obj.justifyContent = '';
    }

    return obj;
  }

  public isSorteable(field: string | TableField | any): boolean {
    return field.sortable ? true : false;
  }

  public isTableField(field: string | TableField): field is TableField {
    return this.tableService.isTableField(field);
  }

  public masterToggle() {
    if (this.isAllSelected) {
      this.isAllSelected = false;
    } else {
      this.isAllSelected = true;
    }

    this.selectAll.emit(this.isAllSelected);
  }

  public orderBy(event: any, field: string | TableField) {
    const key = this.getKeyByField(field);
    if (this.sortIcons) {
      for (const sortIcon of this.sortIcons) {
        let element = sortIcon.nativeElement;

        this.sendEmitOrder(element, key);
      }
    }
  }

  public sendEmitOrder(el: any, key: any) {
    if (el.getAttribute('data-sort') === key) {
      let tableSortEvent: TableSortEvent = { key: key, direction: TableSortDirection.EMPTY };

      if (el.classList.contains('fa-sort')) {
        el.classList.remove('fa-sort');
        el.classList.add('fa-sort-down');
        tableSortEvent.direction = TableSortDirection.ASCENDING;
      } else if (el.classList.contains('fa-sort-down')) {
        el.classList.remove('fa-sort-down');
        el.classList.add('fa-sort-up');
        tableSortEvent.direction = TableSortDirection.DESCENDING;
      } else if (el.classList.contains('fa-sort-up')) {
        el.classList.remove('fa-sort-up');
        el.classList.add('fa-sort');
      }

      this.sortChanged.emit(tableSortEvent);
    } else {
      if (el.classList.contains('fa-sort-down')) {
        el.classList.remove('fa-sort-down');
        el.classList.add('fa-sort');
      } else if (el.classList.contains('fa-sort-up')) {
        el.classList.remove('fa-sort-up');
        el.classList.add('fa-sort');
      }
    }
  }
}

import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import moment from 'moment';
import { ContainerComponent } from "./components/container/container.component";

@Component({
    selector: 'az-glicosilada',
    styleUrls: ['./glicosilada.component.css'],
    templateUrl: './glicosilada.component.html'
})
export class GlicosiladaComponent implements OnInit, OnChanges {
    public readonly BASE_TRANSLATE = "admin.glicosilada";

    @Input() data: any = {};
    @Input() loading: boolean = false;

    info: any = {
        day_test: "-- --- ----",
        value_test: "--"
    };


    constructor(
        public dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
    }
    public ngOnChanges(): void {
        if (this.data.day_test && typeof this.data.value_test === 'number') {
            this.info.day_test = `${moment(String(this.data.day_test || "").substring(0, 10)).format("D MMMM YYYY")}`;
            this.info.value_test = this.data.value_test + "%";
        } else {
            this.info = {
                day_test: "-- --- ----",
                value_test: "--"
            };
        }

    }
    openComponent() {
        const dialogRef = this.dialog.open(ContainerComponent, {
            panelClass: ['paneGlicosilada'],
            data: this.data
        });
        dialogRef.afterClosed().subscribe(() => { });
    }

}
import moment from "moment";
const regexFecha = /^([0-2][0-9]|3[01])\/(0[1-9]|1[0-2])\/(20[0-9]{2})(?: - ([01][0-9]|2[0-3]):([0-5][0-9])|( [0-1][0-9]):([0-5][0-9]) (am|pm))$/i;

export function replaceAccents(value: string) {
  let texto: string | number = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(" mmHg", "").replace(" mg/dL", "").replace(" kg", "");
  texto = texto.replace(/[^a-zA-Z0-9 -/:]/g, "");
  if (regexFecha.test(texto)) {
    texto = texto.replace(" - ", " ").toUpperCase()
    texto = moment(`${texto.substring(6, 10)}-${texto.substring(3, 5)}-${texto.substring(0, 2)} ${texto.substring(11, 20)}`).valueOf()
  } else if (!isNaN(Number(texto))) {
    texto = Number(texto)
  }
  return texto
}

export function newGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import moment from "moment";
import { GrupoServices } from '../../services/grupo.service';
import { Router } from '@angular/router';
import { NgEventBus } from 'ng-event-bus';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-chart-buscamina',
    templateUrl: './chart-buscamina.component.html',
    styleUrls: ['./chart-buscamina.component.css'],
    providers: [GrupoServices]
})
export class ChartBuscaminaComponent implements OnInit {
    @ViewChild('chartcomponents', { static: true }) canvasGraficaPunto: ElementRef;

    public readonly BASE_TRANSLATE = "controlPrograma";

    loading: boolean = false;

    idgrupo: number = 0;
    title: string = '---';
    subtitle: string = '---';
    info: any[] = [];
    dataPreview: any;

    fActual: any;
    fChange: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        public dialog: MatDialog,
        public _router: Router,
        public dialogRef: MatDialogRef<ChartBuscaminaComponent>,
        private grupoServices: GrupoServices,
        private eventbus: NgEventBus
    ) {
        moment.locale(localStorage.getItem(`azh_${environment.name}-language`) != null ? localStorage.getItem(`azh_${environment.name}-language`) : 'es');
        eventbus.on("translate:change").subscribe((t: any) => {
            moment.locale(t._data);
        });
        this.title = data.title;
        this.subtitle = data.subtitle;
        this.idgrupo = data.id;
        this.dataPreview = data.dataPreview;
    }

    ngOnInit(): void {
        this.fActual = moment(new Date).format();
        this.fChange = this.fActual;
        if (!this.data.is_load) {
            this.getBuscamina(this.idgrupo, this.fChange)
        } else if (this.data.is_load && localStorage.getItem('azh_buscamina_cache_' + this.idgrupo)) {
            const { info, filter } = JSON.parse(localStorage.getItem('azh_buscamina_cache_' + this.idgrupo))
            this.info = info;
            this.fChange = filter.fecha;
        }
        this.eventbus.on('metricasvalidic:reonly').subscribe((res: any) => {
            if (res._data.msg === 'OK') {
                this.getBuscamina(this.idgrupo, this.fChange);
            }
        });
    }

    formaterMesAnno(f: any) {
        let fecha = moment(f).format("MMMM YYYY");
        return fecha;
    }

    M_ANTERIOR() {
        let x = moment(this.fChange).format();
        let f = x.split("T")[0];
        let x2 = parseInt(f.split("-")[1]) > 1
            ? moment(new Date(`${parseInt(f.split("-")[0])}-${parseInt(f.split("-")[1]) - 1}-1`)).format()
            : parseInt(f.split("-")[1]) === 1
                ? moment(new Date(`${parseInt(f.split("-")[0]) - 1}-12-1`)).format()
                : '';

        this.fChange = x2;
    }

    M_SIGUIENTE() {
        let x = moment(this.fChange).format();
        let f = x.split("T")[0];
        let x2 = parseInt(f.split("-")[1]) < 12
            ? moment(new Date(`${parseInt(f.split("-")[0])}-${parseInt(f.split("-")[1]) + 1}-1`)).format()
            : parseInt(f.split("-")[1]) === 12
                ? moment(new Date(`${parseInt(f.split("-")[0]) + 1}-1-1`)).format()
                : '';

        if (this.fChange.split("T")[0] != this.fActual.split("T")[0]) {
            this.fChange = x2;
        }
    }

    S_MEDICIONES() {
        this.getBuscamina(this.idgrupo, this.fChange);
    }

    getBuscamina(id: number, fecha: any) {
        this.info = [];
        this.loading = true;
        this.grupoServices.getGraficaBuscamina(id, fecha).subscribe((res: any) => {
            this.info = res.retObject;
            this.loading = false;
            localStorage.setItem('azh_buscamina_cache_' + id, JSON.stringify({ info: res.retObject, filter: { fecha } }))
        }, error => {
            console.log(error);
            this.loading = false;
        });
    }

    CloseModal() {
        this.dialogRef.close();
    }
}
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SalaInterface } from './interfaces/sala.interface';
import { SmsInterface } from './interfaces/sms.interface';

@Injectable({
  providedIn: "root"
})
export class ChatV2Service {
  private headers = new HttpHeaders({'Accept': 'application/json' });
  private url: string;

  constructor(private _http: HttpClient) {
    this.url = environment.URL_LAMBDA;
  }

  getMessageSala(id_sala: number, page: number, numitemsORempty: number) {
    let serviceURL = `${this.url}services.chatv2/list-chats/${id_sala}/${page}/${numitemsORempty}`;
    return this._http.get(serviceURL, { headers: this.headers });  
  }

  postMessageSala(data: SmsInterface) {
    let serviceURL = `${this.url}services.chatv2/create-room-sms`;
    return this._http.post(serviceURL, data, { headers: this.headers });  
  }

  postAddAndGetSala(data: SalaInterface) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let serviceURL = `${this.url}services.chatv2/create-room`;
    return this._http.post(serviceURL, data, { headers: this.headers });
  }

  putCanAnswerBeneficiary(data: {id_sala: number}) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let serviceURL = `${this.url}services.chatv2/status-room`;
    return this._http.put(serviceURL, data, { headers: this.headers });
  }
}

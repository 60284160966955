import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { String } from 'typescript-string-operations';
import { environment } from '../../../../../environments/environment';


@Injectable()
export class GlicosiladaService {

  private headers = new HttpHeaders({ 'Accept': 'application/json' });
  private url: string;

  constructor(private _http: HttpClient) {
    this.url = environment.URL_LAMBDA;
  }

  get(idpaciente: number, page: number = 1, pagesize: number = 25, estado: boolean) {
    let serviceURL = String.Format('{0}services.glicosilada/{1}/{2}/{3}/{4}', this.url, idpaciente, page, pagesize, estado);
    return this._http.get(serviceURL, { headers: this.headers });
  }

  add(item: any) {
    let serviceURL = String.Format('{0}services.glicosilada/crear', this.url);
    return this._http.post(serviceURL, (item), { headers: this.headers });
  }

  update(id: number, item: any) {
    let serviceURL = String.Format('{0}services.glicosilada/editar/{1}', this.url, id);
    return this._http.put(serviceURL, (item), { headers: this.headers });
  }

  toggleEstado(id: number) {
    let serviceURL = String.Format('{0}services.glicosilada/estado/{1}', this.url, id);
    return this._http.put(serviceURL, ({}), { headers: this.headers });
  }
}

import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'fechasTransform',
    
})
export class FechasTransformPïpe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 29)
                return 'Just Now';
            const rangos = {
                'año': 31536000,
                'meses': 2592000,
                'semanas': 604800,
                'días': 86400,
                'horas': 3600,
                'minutos': 60,
                'segundos': 1
            };
            let contador;
            for (const i in rangos) {
                contador = Math.floor(seconds / rangos[i]);
                if (contador > 0)
                    if (contador === 1) {
                        return contador + ' ' + i + ' atras';
                    } else {
                        return contador + ' ' + i + ' atras';
                    }
            }
        }
        return value;
    }

}

import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-e401',
    templateUrl: './E401.components.html',
    styleUrls: ['./E401.components.css']
})
export class E401Component {
    constructor(
        public _router: Router
    ) {
        setTimeout(() => {
            this._router.navigateByUrl(`inicio`);
        }, 3000);
    }
}
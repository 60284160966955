import { Component, Input, OnInit } from '@angular/core';
import { Profile } from 'src/app/models/profile.model';
import { AuthenticatedKeycloak } from 'src/app/services/authenticated-keycloak.service';

@Component({
  selector: 'app-nav-lateral',
  templateUrl: './nav-lateral.component.html'
})
export class NavLateralComponent implements OnInit {
  public readonly BASE_TRANSLATE = "shared";

  profile: Profile;
  @Input() idGrupo: number;
  @Input() showNavLateral: any;
  nombre: string;
  cargo: boolean;
  logo: string;
  imagen: string;
  rut: number;
  rol: any[] = [];
  mostrarGrupos = true;

  constructor(
    public keycloak: AuthenticatedKeycloak
  ) {
    keycloak.getProfileMe().then(res => {
      this.rol = res.perfil;
    });
  }

  ngOnInit() {
    // code
  }
}

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { KeycloakSecurityService } from './keycloak-security.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private _keycloakSecurityService: KeycloakSecurityService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return of(request).pipe(
      flatMap(() => {
        return new Promise((resolve, reject) => {
          this._keycloakSecurityService.kc.updateToken(0).then((refreshed) => {
            resolve(refreshed)
          }).catch((reject));
        })
      }),
      map(() => {
        return request.clone({
          setHeaders: {
            'Authorization': `Bearer ${localStorage.getItem(`azh_${environment.name}_token__from_support`) || this._keycloakSecurityService.kc.token}`,
            'Accept-Language': localStorage.getItem(`azh_${environment.name}-language`) != null ? localStorage.getItem(`azh_${environment.name}-language`) : 'es'
          }
        });
      }),
      flatMap((newRequest) => {
        return next.handle(newRequest)
      })
    )
  }
}

import { Injectable } from "@angular/core";
import moment from "moment";
import { TableAction } from "../az-table";

@Injectable({
    providedIn: "root"
})
export class ValidatorsUtils {
    constructor() { }

    public getActionsToggleAndEdit(baseTranslate: string): TableAction[] {
        return [
            {
                name: 'toggleCheck',
                title: `${baseTranslate}.list.tooltip.active`,
                icon: 'fa fa-toggle-on',
                tooltip: `${baseTranslate}.list.tooltip.active`,
                toggle: "fa fa-toggle-off",
            },
            {
                name: 'editar',
                title: `${baseTranslate}.list.tooltip.edit`,
                icon: 'fas fa-edit',
                tooltip: `${baseTranslate}.list.tooltip.edit`
            },
        ] as TableAction[];
    }

    public getNumberWeek(_fecha: any) {
        return `${moment().format("YYYY")}-W${moment().isoWeek()}`;
    }

    public getWeeks(_fecha: any, iteraciones = 7) {
        let current = moment().format('YYYY-MM-DD')
        let _structuraFecha = moment(_fecha).isoWeekday(1).startOf('isoWeek')

        const s = iteraciones
        let semana = []
        for (let i = 0; i < s; i++) {
            semana.push({
                date: _structuraFecha.format('YYYY-MM-DD'),
                date_ms: moment(_structuraFecha.format('YYYY-MM-DD HH:mm')).valueOf(),
                year: _structuraFecha.format('YYYY'),
                day: _structuraFecha.format('DD'),
                day_text: _structuraFecha.format('ddd').replace('.', ''),
                month: _structuraFecha.format('MM'),
                month_text: _structuraFecha.format('MMM').replace('.', ''),
                past: moment(current).valueOf() > _structuraFecha.valueOf() ? true : false,
                current: _structuraFecha.valueOf() === moment(current).valueOf() ? true : false
            })
            _structuraFecha.add(1, 'd')
        }
        return semana
    }

    public onlyTextAndSpaces(event: any) {
        event.target.value = event.target.value.trimStart();
        event.target.value = event.target.value.replace(/[`1234567890`~!°@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        if (event.key === '-' && event.target.value.length === 1) {
            event.target.value = "";
        }
        let regex = /^[a-zA-Z\u00C0-\u017F -]+$/;
        if (regex.test(event.key) || event.key === 'Backspace' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Tab' || event.key === 'Delete') {
            return true;
        } else {
            return false;
        }
    }

    public onlyTextNumberAndSpaces(event: any) {
        event.target.value = event.target.value.trimStart();
        event.target.value = event.target.value.replace(/[`~!$%^&*()|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        if (event.key === '-' && event.target.value.length === 1) {
            event.target.value = "";
        }
        let regex = /^[a-zA-Z0-9\u00C0-\u017F °@#_-]+$/;
        if (regex.test(event.key) || event.key === 'Backspace' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Tab' || event.key === 'Delete') {
            return true;
        } else {
            return false;
        }
    }

    public onlyTextNumberSignAndSpaces(event: any) {
        event.target.value = event.target.value.trimStart();
        event.target.value = event.target.value.replace(/[`^()|+\=<>\\\/]/gi, '');
        let regText: string = "^[a-zA-Z0-9\\u00C0-\\u017F ?¿!¡.,-_\\[\\]\\{\\}&%\\$\"'*~@:;]+$";
        let regex = new RegExp(regText);
        if (regex.test(event.key) || event.key === 'Backspace' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Tab' || event.key === 'Delete') {
            return true;
        } else {
            return false;
        }
    }

    public onlyNumberAndPoints(event: any, validpoint: boolean = false, validcoma: boolean = false) {
        event.target.value = event.target.value.trim();
        let regex = validpoint && !validcoma ? /^[0-9\.]$/ : !validpoint && validcoma ? /^[0-9\,]$/ : validpoint && validcoma ? /^[0-9\,.]$/ : /^[0-9]$/;
        if (regex.test(event.key) || event.key === 'Backspace' || event.key === 'ArrowRight' || event.key === 'ArrowLeft' || event.key === 'Tab' || event.key === 'Delete') {
            if (validpoint && !validcoma) {
                if (event.target.value.includes('.') && event.key === '.') {
                    return false;
                } else {
                    return true;
                }
            } else if (!validpoint && validcoma) {
                return true;
            } else if (validpoint && validcoma) {
                if (event.target.value.includes('.') && event.key === '.') {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    public downloadFile(file: any, nameFile: string, type: string, ext: string) {
        let binary = atob(`${file}`);

        let array = [];
        for (let j = 0; j < binary.length; j++) {
            array.push(binary.charCodeAt(j));
        }

        let contenido = new Blob([new Uint8Array(array)], {
            type: type
        });

        let a = document.createElement('a');
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(contenido);
        a.href = url;
        a.download = nameFile + '.' + ext;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
    public scappingText = (text: string) => {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const c = a.toUpperCase()
        const d = b.toUpperCase()
        const p = new RegExp(a.split('').join('|'), 'g')
        const p2 = new RegExp(c.split('').join('|'), 'g')


        return text.toString()
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(p2, e => d.charAt(c.indexOf(e))) // Replace special characters
            .replace(/&/g, 'y') // Replace & with 'y'
            .replace(/\(/g, '') // Replace ( with ''
            .replace(/\)/g, '') // Replace ) with ''
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, '') // Trim - from end of text
    }

    public getEdad(dateString: string) {
        let hoy: Date = new Date()
        let fechaNacimiento: Date = new Date(dateString)
        let edad: number = hoy.getFullYear() - fechaNacimiento.getFullYear()
        let diferenciaMeses: number = (hoy.getMonth() + 1) - (fechaNacimiento.getMonth() + 1)
        if (
            diferenciaMeses < 0 ||
            (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
        ) {
            edad--;
        }
        return edad;
    }

    public clearDate(tis: any): void {
        let v: string = tis.form.get('start')?.value;
        let v2: string = tis.form.get('end')?.value;

        if (v && v2) {
            const dateB = moment(v);
            const dateC = moment(v2);
            if (moment(v2).valueOf() < moment(v).valueOf() || moment(v).valueOf() > moment(v2).valueOf() || Math.abs(dateB.diff(dateC, 'days')) > 93) {
                tis.form.get('end').setValue("")
            }
        }
    }

    public clearDateEnd(tis: any): void {
        let v: string = tis.form.get('date_start')?.value;
        let v2: string = tis.form.get('date_end')?.value;

        if (v) {
            if (moment(v).valueOf() >= moment(v2).valueOf()) {
                tis.form.get('date_end').setValue("")
            }
            if (moment(v).valueOf() < moment(tis.minDate).valueOf()) {
                tis.form.get('date_start').setValue("")
            }
        }
    }

    public clearDateEnd2(tis: any, op: boolean = true): void {
        let v: string = tis.form.get('date_end')?.value;
        let v2: string = this.minDateEnd(tis);
        if (v) {
            if (moment(v).valueOf() < moment(v2).valueOf()) {
                tis.form.get('date_end').setValue("")
            }
        }
        if (op == true) this.clearDateEnd3(tis)
    }
    public clearDateEnd3(tis: any): void {
        let v: string = tis.form.get('fechafin')?.value;
        let min: string = tis.form.get('date_start')?.value ? tis.form.get('date_start')?.value : tis.minformato
        let max: string = tis.form.get('date_end')?.value ? tis.form.get('date_end')?.value : tis.minformato
        if (v) {
            if (moment(v).valueOf() < moment(min).valueOf() || moment(v).valueOf() > moment(max).valueOf()) {
                tis.form.get('fechafin').setValue("")
            }
        }
    }
    public minDateEnd(tis: any): string {
        return tis.form.get('date_start')?.value ? moment(tis.form.get('date_start')?.value).add(1, "days").format("YYYY-MM-DD") : moment(tis.minDate).add(1, "days").format("YYYY-MM-DD");
    }

    public setName(id: number, tis: any, array = []): void {
        for (const i of array) {
            if (Number(i.idsurveys) == Number(id) || Number(i.id) == Number(id)) {
                tis.form.get('name').setValue(this.scappingText(i.title))
                break;
            }
        }
    }
    public closeModalDialog(rest: any, tis: any) {
        tis.dialogRef.close(rest);
    }

    public onSaveComplete(tis: any): void {
        tis.form.reset();
        tis.dialogRef.close(true);
    }
    // Método para calcular el porcentaje de grasa corporal
    public calcBodyFat = ({
        height = 0,
        weight = 0,
        genre = "-",
        age = 0,
    }) => {
        height = height / 100;
        return age <= 0 || weight <= 0 ? 0 : Number(((1.20 * (weight / (height * height))) + (0.23 * age) - (10.8 * (genre == "M" ? 1 : 2)) - 5.4).toFixed(2));
    }
    // Método para calcular la masa muscular
    public calcBodyMassMuscle = ({
        height = 0,
        weight = 0,
        genre = "-",
        age = 0,
    }) => {
        let grasaCorporal = this.calcBodyFat({
            height,
            weight,
            genre,
            age
        });
        if (genre == 'M') {
            return Number(((weight * (1 - grasaCorporal / 100)) * 0.85).toFixed(2));
        } else if (genre == 'F') {
            return Number(((weight * (1 - grasaCorporal / 100)) * 0.75).toFixed(2));
        } else {
            return 0;
        }
    }
    // Método para calcular el porcentaje de agua corporal
    public calcBodyWater = ({
        weight = 0,
        genre = "-",
    }) => {
        if (genre == 'M') {
            return Number((0.60 * weight).toFixed(2));
        } else if (genre == 'F') {
            return Number((0.50 * weight).toFixed(2));
        } else {
            return 0;
        }
    }
    // Método para calcular la masa ósea
    public calcBodyMassBone = ({
        weight = 0,
        genre = "-",
    }) => {
        if (genre == 'M') {
            return Number((weight * 0.15).toFixed(2));
        } else if (genre == 'F') {
            return Number((weight * 0.12).toFixed(2));
        } else {
            return 0;
        }
    }
    // Método para calcular IMC 
    public calcIMC = ({
        weight,
        height,
    }) => {
        weight = !weight || weight == '' ? 0 : weight;
        height = !height || height == '' ? 0 : height;
        height = height / 100;
        let imc = height == 0 || height == Infinity ? 0 : weight / (height * height);
        return Number(imc.toFixed(2));
    }
}
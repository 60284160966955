import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: "root"
})
export class ProfileService {
  private headers = new HttpHeaders({ 'Accept': 'application/json' });
  private url: string;

  constructor(private _http: HttpClient) {
    this.url = environment.URL_LAMBDA;
  }

  getAvatar() {
    let serviceURL = `${this.url}services.profile/avatar-user`;
    return this._http.get(serviceURL, { headers: this.headers });
  }

  getDatos(is_paciente: boolean) {
    let serviceURL = `${this.url}services.profile/datos/${is_paciente}`;
    return this._http.get(serviceURL, { headers: this.headers });
  }

  putAvatar(data: any) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let serviceURL = `${this.url}services.profile/update-avatar-user`;
    return this._http.post(serviceURL, data, { headers: this.headers });
  }

  putDataUser(data: any) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let serviceURL = `${this.url}services.profile/update-datos`;
    return this._http.post(serviceURL, data, { headers: this.headers });
  }

  postExchangeRol(data: any) {
    this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let serviceURL = `${this.url}services.superadmin/exchange_rol`;
    return this._http.post(serviceURL, data, { headers: this.headers });
  }

  getListadoEmpresa() {
    let serviceURL = `${this.url}services.superadmin/listado-empresas-simple`;
    return this._http.get(serviceURL, { headers: this.headers });
  }

  getListadoUsuariosByIdc(idc: string) {
    let serviceURL = `${this.url}services.superadmin/listado-usuarios-simple/${idc}`;
    return this._http.get(serviceURL, { headers: this.headers });
  }
}

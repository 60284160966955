import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JsonResult } from 'src/app/models/json-result.model';
import { GlicosiladaService } from '../../../services/glicosilada.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { ValidatorsUtils } from 'src/app/shared/utils/validators.util';
import  moment from 'moment';

@Component({
  selector: 'app-crear-glicosilada',
  templateUrl: './crear-glicosilada.component.html',
  providers: [GlicosiladaService]
})
export class crearGlicoComponent implements OnInit {
  public readonly BASE_TRANSLATE = "admin.glicosilada.list.form";
  public readonly BASE_TRANSLATE_SHARED = "shared";

  form: FormGroup;
  cargaservice: boolean = false;
  type: boolean = true;
  public p2_2: RegExp = /^\d{1,2}(\.\d{1,2})?$/;
  maxDate: string = moment().format("YYYY-MM-DD");
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<crearGlicoComponent>,
    public alerts: SnackBarService,
    private fb: FormBuilder,
    private _glicosiladaservice: GlicosiladaService,
    public validatorsUtils: ValidatorsUtils
  ) {
    this.type = this.data.type;
    this.initForm();
  }

  ngOnInit() { }

  initForm() {
    this.form = this.fb.group({
      day_test: [this.data.data.day_test, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      value_test: [this.data.data.value_test, [Validators.required, Validators.pattern(this.p2_2), Validators.maxLength(5), Validators.min(0.00), Validators.max(20)]],
      idpaciente: [this.data.idpaciente, [Validators.required]],
      idprofesional: [this.data.idprofesional, [Validators.required]]
    });
  }

  onSubmit() {
    let set = { ...this.form.value };
    set.day_test = `${set.day_test} ${moment().format("HH:mm:ss")}`;
    this.cargaservice = true;
    let __suscribe: any = this.data.type == true ? this._glicosiladaservice.add(set) : this._glicosiladaservice.update(this.data.data.idglicosilada_history, set);
    __suscribe.subscribe(
      (result: JsonResult) => {
        this.alerts.success(result.retMessage);
        this.dialogRef.close(true);
        this.cargaservice = false;
      }, (error: { error: { retMessage: string; }; }) => {
        this.cargaservice = false;
        if (error.error.retMessage != "") {
          this.alerts.error(error.error.retMessage);
        }
      });
  }
}
export class Profile {
    email: string;
    employeeID: string;
    family_name: string;
    apellidopaterno: string;
    nickname: string;
    name: string;

    @alias('picture')
    picture: string;

    @alias('me')
    me: any;

    rut: number;

    genero:string;

    setCustomAttributes(){
        this.employeeID = this.me.document_id;
        this.family_name = this.me.nombre;
        this.apellidopaterno = this.me.apellidoPaterno;
        this.name = this.me.nombre;
        this.rut  = Number(this.me.rut);
        this.email =this.me.email;
        this.genero = this.me.genero;
        this.picture = this.picture
        || `https://ui-avatars.com/api/?name=${this.family_name}+${this.apellidopaterno}&background=${this.genero === 'M'?'1D76D0':'E91E63'}&color=fff`;
    }

    constructor(args: any) {
      let fields = (<any>Profile)._alias;
      for (let field in fields) {
        let alias2 = fields[field];
        this[field] = args[alias2] instanceof Array ? args[alias2].slice() : this[field] = args[alias2];
      }
      this.setCustomAttributes();
    }
  }

  function alias(a: string) {
    return function (target: Object, propertyKey: string | symbol) {
      target['constructor']['_alias'] = target['constructor']['_alias'] || {};
      target['constructor']['_alias'][propertyKey] = a;
    };
  }


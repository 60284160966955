import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ChartBuscaminaComponent } from "src/app/grupos/chart-buscamina/chart-buscamina.component";
import { JsonResult } from "src/app/models/json-result.model";
import { GrupoServices } from "src/app/services/grupo.service";

@Component({
    selector: 'az-metricas',
    templateUrl: './metricas.component.html'
})
export class AzMetricasComponent implements OnInit {
    public readonly BASE_TRANSLATE = "dashboard";
    public readonly BASE_TRANSLATE_SHARED = "shared";
    @Input() idprogram: number = 0;
    @Input() activeBuscamina: boolean = false;

    @Input() title: string = "";
    @Input() subtitle: string = "";

    info: any = {};
    loading: boolean = false;
    is_load: boolean = false;
    constructor(
        private grupoService: GrupoServices,
        public dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.getMetricasGroup();
    }

    getMetricasGroup() {
        this.loading = true;

        this.grupoService.getMetricaGroup(this.idprogram).subscribe((trae: JsonResult) => {
            this.info = trae.retObject;
            this.loading = false;
        }, error => {
            console.log(error);
            this.loading = false;
        });
    }

    openChartBuscamina(id: number) {
        this.dialog.open(ChartBuscaminaComponent, {
            maxWidth: "100vw",
            panelClass: ["modal-full-screen", "chart-buscamina-modal"],
            data: {
                title: this.title,
                subtitle: this.subtitle,
                id: id,
                is_load: this.is_load
            }
        });
        this.is_load = true;
    }
}
<p class="title" [class]="loading ? 'az-skeleton' : ''">
    {{ BASE_TRANSLATE + '.widget_cars_programas.text_beneficiarios' | translate }}
</p>

<div class="graph-circle">
    <div class="item" [class]="loading ? 'az-skeleton az-skeleton-x-3 mh-48' : ''">
        <p class="text">{{ info?.conmeta }}</p>
        <p class="text">{{ BASE_TRANSLATE + '.widget_cars_programas.text_con_meta' | translate }}</p>
    </div>
    <div class="item" [class]="loading ? 'az-skeleton az-skeleton-x-3 mh-48' : ''">
        <p class="text">{{ info?.sinmeta }}</p>
        <p class="text">{{ BASE_TRANSLATE + '.widget_cars_programas.text_sin_meta' | translate }}</p>
    </div>
    <div class="item" [class]="loading ? 'az-skeleton az-skeleton-x-3 mh-48' : ''">
        <p class="text">{{ info?.total }}</p>
        <p class="text">{{ BASE_TRANSLATE + '.widget_cars_programas.text_total' | translate }}</p>
    </div>
</div>
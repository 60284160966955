import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'etiqueta'
})
export class EtiquetaPipe implements PipeTransform {
    transform(value: string): string {
        return value.substr(0, 3);
    }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgEventBus } from 'ng-event-bus';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
    selector: 'app-cropper-image-form',
    templateUrl: './cropper-image-form.component.html',
    styleUrls: ['./cropper-image-form.component.css'],
    providers: []
})
export class CropperImageComponentForm implements OnInit {
    public readonly BASE_TRANSLATE_SHARED = "shared";
    cargaservice: boolean = false;
    loading: boolean = false;

    is_paciente: boolean = false;

    listSex: any[] = [];

    imageChangedEvent: any = '';
    imageFile: File;
    croppedImage: any = '';

    image: any = "assets/img/avatar.png";

    containWithinAspectRatio = false;
    canvasRotation = 0;
    transform: ImageTransform = {};
    showCropper: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<CropperImageComponentForm>,
        private eventBus: NgEventBus,
        public alert: SnackBarService
    ) { }

    ngOnInit() {
        // code
    }

    fileChangeEvent(event: any): void {
        let { files } = event.target;
        files = files[0];

        let _sizeKiloBytes = parseInt(files.size) / 1024;
        let type = files.type;

        if (_sizeKiloBytes > 1000) {
            this.alert.warn('El peso de la imagen, no puede ser mayor a 1MB.');
        } else {
            if (type == "image/jpeg" || type == "image/png" || type == "image/jpg") {
                this.imageChangedEvent = event;
                this.imageFile = files;
            } else {
                this.alert.warn('El formato de la imagen no cumple con el siguiente. JPG, JPEG o PNG');
            }
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        this.showCropper = true;
    }

    submit() {
        this.eventBus.cast("image:form:articulo", { avatar: this.croppedImage, file: this.imageFile });
        this.dialogRef.close(true);
    }
}
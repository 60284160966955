import { Component, Input, OnInit } from "@angular/core";
import { JsonResult } from "src/app/models/json-result.model";
import { GrupoServices } from "src/app/services/grupo.service";

@Component({
    selector: 'az-metas-beneficiarios',
    templateUrl: './metas-beneficiarios.component.html'
})
export class AzMetasBeneficiariosComponent implements OnInit {
    public readonly BASE_TRANSLATE = "dashboard";
    
    @Input() idprogram: number = 0;
    info: any = {
        conmeta: 0,
        sinmeta: 0,
        total: 0
    };

    loading: boolean = false;

    constructor(
        private grupoService: GrupoServices
    ) {}

    ngOnInit(): void {
        this.getMetasBeneficiarios();
    }

    getMetasBeneficiarios() {
        this.loading = true;

        this.grupoService.getMetaBeneficiario(this.idprogram).subscribe((trae: JsonResult) => {
            this.info = trae.retObject;
            this.loading = false;
        }, error => {
            console.log(error);
            this.loading = false;
        });
    }
}
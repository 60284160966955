<em class="fas fa-times cerrar-modal" (click)="this.dialogRef.close(false);"></em>
<h4 mat-dialog-title class="titulo-modal">{{ BASE_TRANSLATE + '.title' | translate }}</h4>
<form *ngIf="!loading" [formGroup]="form" class="form"  style="width: 400px !important;">
	<mat-dialog-content>
		<div class="mdl-grid">
			<div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
				<div class="mdl-grid">
					<div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
						<div class="form-group">
							<mat-select [placeholder]="(BASE_TRANSLATE + '.form.input_idc' | translate)"
								ngDefaultControl formControlName="idc" class="form-control"
								(selectionChange)="listadoUsuarios($event)">
								<mat-option *ngFor="let s of listClient" [value]="s.idc">
									{{ capitalizeLetter(s.client_name) }}
								</mat-option>
							</mat-select>
						</div>
					</div>
					<div class="mdl-cell mdl-cell--12-col mdl-cell--12-col-tablet mdl-cell--12-col-phone">
						<div class="form-group">
							<mat-select [placeholder]="(BASE_TRANSLATE + '.form.input_user' | translate)"
								ngDefaultControl formControlName="email" class="form-control">
								<mat-option *ngFor="let s of listUsers" [value]="s.email">
									{{ capitalizeLetter(s.nombre) }} {{ capitalizeLetter(s.apellidopaterno) }}
									({{toLine(s.roles)}})
								</mat-option>
							</mat-select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button (click)="dialogRef.close()" type="button"
			class="mdl-button mdl-button-cancel close-modal modal__close">{{
			BASE_TRANSLATE_SHARED + '.buttons.cancel' | translate}}</button>
		<button [disabled]="!form.valid || cargaservice" type="button" (click)="form.valid ? submit() : ''"
			class="mdl-button close-modal modal__close">{{
			BASE_TRANSLATE_SHARED + '.buttons.add' | translate }} <az-loader-button [show-loading]="cargaservice"
				[color]="'var(--primario)'" [disabled]="!form.valid || cargaservice ? true : false"></az-loader-button>
		</button>
	</mat-dialog-actions>
</form>
<az-loader-base [show-loading]="loading"></az-loader-base>
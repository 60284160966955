<div class="w-100">
    <div class="bold w-85">
        {{(BASE_TRANSLATE + '.widget_cars_programas.text_tooltip_glico' | translate)}}
    </div>
    <div class="w-15">
        <em class="c-pointer icon p-0 fas fa-eye" (click)="openComponent()"></em>
    </div>
    <div class="w-40">
        {{info?.day_test}}
    </div>
    <div class="w-60 fz-12">
        {{ info?.value_test }}
    </div>
</div>
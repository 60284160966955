import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticatedKeycloak } from './services/authenticated-keycloak.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
	public readonly BASE_TRANSLATE_SHARED = 'shared';
	public environment = environment;
	title = 'Azurian - Health';

	timeout: number = 15;

	constructor(
		public keycloak: AuthenticatedKeycloak
	) { }

	async ngOnInit() {
		if (this.keycloak.roles.length <= 1) {
			this.keycloak.roles.forEach(res => {
                if (res.nombre.toLowerCase() === 'paciente') {
					let x = setInterval(() => {
						this.timeout--;
						if (this.timeout === 0) {
							this.keycloak.logout();
							clearInterval(x);
						}
					}, 1000);
                }
            });
		}
	}
}
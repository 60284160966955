import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'etiquetaExport'
})
export class EtiquetaExportPipe implements PipeTransform {
   transform(value: any[]): string {
      let text: string = value.map(e => e.nombre).toString();
      return text;
   }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Pipe({
    name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
    transform(value: string, canbeFutureDate?:boolean): string {
        if (value) {
            value = value.replace('[UTC]', '');
            value = (value.indexOf('[') !== -1) ? value.substr(0, value.indexOf('[')) : value;
            if(moment(value)>moment() && !canbeFutureDate){
                value = moment().format();
            }
            return value;
        } else {
            return value;
        }
    }
}

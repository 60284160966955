import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "./guards/auth.guard";
import { E401Component } from './shared/components/errors/401/E401.components';

const APP_ROUTES: Routes = [
    {
      path: 'inicio', 
      loadChildren: () => import('src/app/grupos/grupos.module').then(m => m.GruposModule)
    },
    {
      path: 'programa', 
      loadChildren: () => import('src/app/grupo/grupo.module').then(m => m.GrupoModule)
    },
    {
      path: 'admin',
      loadChildren: () => import('src/app/admin/admin.module').then(m => m.AdminModule)
    },
    {
      path: 'superadmin',
      loadChildren: () => import('src/app/superadmin/superadmin.module').then(m => m.SuperAdminModule),
      canActivate: [AuthGuard],
      data: { roles: ["super_admin"] }
    },
    {
      path: 'upi',
      loadChildren: () => import('src/app/upi/upi.module').then(m => m.UpiModule),
      data: { roles: ["upi"] }
    },
    {
      path: 'no-autorizado',
      component: E401Component
    },
    {
      path: '',
      pathMatch: 'full',
      redirectTo: '/inicio'
    },
    {
      path: '**',
      pathMatch: 'full',
      redirectTo: 'inicio'
    }
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
<mat-form-field>
    <mat-label>{{matLabel}}</mat-label>
    <mat-select [formControl]="MultiCtrl" [placeholder]="placeholderLabel" [multiple]="true" #multiSelect (selectionChange)="onSelectionChange()">
        <div class="mat-option select-all" (click)="onSelectAllClick($event)">
            <mat-pseudo-checkbox [(state)]="state" class="mat-option-pseudo-checkbox select-all"></mat-pseudo-checkbox>
            <span class="mat-option-text">{{selectAllLabel}}</span>
        </div>
        <ngx-mat-select-search [formControl]="MultiFilterCtrl" [noEntriesFoundLabel]="noEntriesFoundLabel" [placeholderLabel]="placeholderSearchLabel">

        </ngx-mat-select-search>
        <mat-option *ngFor="let element of filteredMulti | async" [value]="element">
            {{element.name}}
        </mat-option>
    </mat-select>
</mat-form-field>
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class MetasService {
    private headers = new HttpHeaders({ 'Accept': 'application/json' });
    public url: string;
    options:any;

    constructor(public _http: HttpClient) {
        this.url = environment.URL_LAMBDA;
    }

    getAllListadoMetricas(idGrupo: number) {
        let serviceURL = `${this.url}services.metas.paciente/listadoMetricas/${idGrupo}`;
        return this._http.get(serviceURL,{ headers: this.headers });
    }

    postNuevaMeta(meta:any) {
        let serviceURL = `${this.url}services.metas.paciente/nuevaMeta`;
        return this._http.post(serviceURL,(meta),{ headers: this.headers });
    }

    putActualizarMeta(meta:any) {
        let serviceURL = `${this.url}services.metas.paciente/actualizarMeta`;
        return this._http.put(serviceURL,(meta),{ headers: this.headers });
    }

    deleteMeta(idmeta:number){
        let serviceURL = `${this.url}services.metas.paciente/eliminar/${idmeta}`;
        return this._http.delete(serviceURL, { headers: this.headers });
    }

    getDetalle(idmeta:number) {
        let serviceURL = `${this.url}services.metas.paciente/detalle/${idmeta}`;
        return this._http.get(serviceURL,{ headers: this.headers });
    }

}

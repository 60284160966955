import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'formatoNombre'
})

export class FormatoNombrePipe implements PipeTransform {
   transform(value: string): string {
      let compuesto :string[] = [];
      compuesto = value.split(',');

      let nombre= compuesto[0] + ' \n' + compuesto[1];
      
      return nombre;
   }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as humanizeDuration from 'humanize-duration';

@Pipe({
   name: 'validicFormat'
})
export class ValidicFormatPipe implements PipeTransform {
   transform(value: number, rounded: boolean, formato: string): string {
      let args: any [] = formato.split(',');
      let tipoDato: string = args[0] ? args[0] : null;
      let format: any = args[1] ? args[1] : null;
      let complemento: string = args[2] ? args[2] : null;
      let response: string = '';
      let cantDecimales: number = 0;

      if (!isNaN(Number(format))) {
         cantDecimales = format;
      }

      if (tipoDato) {
         switch (tipoDato) {
            case 'FLOAT': {
               response = (rounded ? Math.round(value * Math.pow(10, cantDecimales)) / Math.pow(10, cantDecimales) : value).toString();
               response += complemento ? complemento : '';
               return response;
            }
            case 'DATETIME': {
               response =  humanizeDuration(value * 1000, { language: 'shortEn',
               languages: {
                 shortEn: {
                   y: () => 'a',
                   mo: () => 'm',
                   w: () => 's',
                   d: () => 'd',
                   h: () => 'hr',
                   m: () => 'min',
                   s: () => 's',
                   ms: () => 'ms',
                 }
               }});
               return response;
            }
            default: {
               console.warn('Formato no válido', response);
               return response;
            }
         }
      }
      console.warn('Formato no válido', response);
      return response;
   }
}

<mat-dialog-content>
    <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">
            <div class="mdl-grid margen-contenedor">
                <div class="mdl-cell mdl-cell--8-col mdl-cell--8-col-tablet mdl-cell--8-col-phone">
                    <h3>
                        <em class="fas fa-layer-group icono-grupo"></em> {{ BASE_TRANSLATE + '.title' | translate }}
                    </h3>
                    <p class="descripcion-seccion">{{ BASE_TRANSLATE + '.subtitle' | translate }}</p>
                </div>
                <div *ngIf="(keycloak.is_profesional | async)|| (keycloak.is_upi | async)"
                    class="mdl-cell mdl-cell--4-col mdl-cell--4-col-tablet mdl-cell--12-col-phone">
                    <div class="fes">
                        <a href="javascript:void(0);" (click)="openDialogCrear(true)"><em class="fas fa-plus"></em>{{
                            BASE_TRANSLATE + '.buttons.add' | translate }}</a>
                    </div>
                </div>
                <div class="mdl-cell mdl-cell--4-col mdl-cell--4-col-tablet mdl-cell--4-col-phone">
                    <mat-form-field>
                        <input matInput (keyup)="filterBySearch($event, 'value_test_format')"
                            [placeholder]="(BASE_TRANSLATE + '.form.input_search' | translate)" />
                    </mat-form-field>
                </div>
                <div class="mdl-cell mdl-cell--4-col mdl-cell--4-col-tablet mdl-cell--4-col-phone">
                    <mat-form-field>
                        <mat-select [placeholder]="(BASE_TRANSLATE + '.form.input_vigente' | translate)" name="vigente"
                            [(value)]="selectedEstado" [(ngModel)]="revision">
                            <mat-option value="all" (click)="selectFiltros()">
                                {{ BASE_TRANSLATE + '.form.options_vigente.opt_1' | translate }}</mat-option>
                            <mat-option value="true" (click)="selectFiltros()">
                                {{ BASE_TRANSLATE + '.form.options_vigente.opt_2' | translate }}</mat-option>
                            <mat-option value="false" (click)="selectFiltros()">
                                {{ BASE_TRANSLATE + '.form.options_vigente.opt_3' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="mdl-cell mdl-cell--4-col mdl-cell--4-col-tablet mdl-cell--4-col-phone"></div>
                <div class="mdl-cell mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone">
                    <az-table [fields]="columns" [items]="(rows$ | async) || []" [actions]="customActions"
                        (actionClick)="onActionClick($event)" [pagination]="true"></az-table>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button type="submit" class="mdl-button close-modal modal__close" (click)="this.dialogRef.close(false);">{{
        BASE_TRANSLATE_SHARED +
        '.buttons.add' | translate}}</button>
</mat-dialog-actions>
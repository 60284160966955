import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'az-loader-fullscreen',
    templateUrl: './loader-fullscreen.component.html',
    styleUrls: ['./loader-fullscreen.component.css'],
    providers: []
})
export class LoaderFullScreenComponent implements OnInit {
    @Input("show-loading") isLoading: boolean = false;

    public readonly BASE_TRANSLATE_SHARED_LOADER = "shared.loader";

    constructor() {}

    ngOnInit(): void {
        this.isLoading = this.isLoading ? this.isLoading : false;
    }
}
<em class="fas fa-times cerrar-modal" (click)="this.dialogRef.close(false);"></em>
<h4 mat-dialog-title class="titulo-modal">{{ BASE_TRANSLATE + ((type == true) ? '.create' : '.update') + '.title' |
  translate
  }}</h4>
<form [formGroup]="form" (ngSubmit)="onSubmit();">
  <mat-dialog-content>
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--6-col mdl-cell--6-col-tablet mdl-cell--6-col-phone fromTop">
        <label class="form-label">{{ BASE_TRANSLATE + '.input_day' | translate }}</label>
        <div class="form-group">
          <input type="date" class="form-control" [max]="maxDate" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" formControlName="day_test">
        </div>
      </div>
      <div class="mdl-cell mdl-cell--6-col mdl-cell--6-col-tablet mdl-cell--6-col-phone">
        <label class="form-label">{{ BASE_TRANSLATE + '.input_value' | translate }}</label>
        <div class="form-group">
          <input class="form-control" (keyup)="validatorsUtils.onlyNumberAndPoints($event,true);"
            (keydown)="validatorsUtils.onlyNumberAndPoints($event,true);" formControlName="value_test" type="text"
            autocomplete="off" maxLength="5">
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button (click)="dialogRef.close()" type="button" class="mdl-button mdl-button-cancel close-modal modal__close">{{
      BASE_TRANSLATE_SHARED + '.buttons.cancel' | translate}}</button>
    <button [disabled]="!form.valid || cargaservice" type="submit" class="mdl-button close-modal modal__close">{{
      BASE_TRANSLATE_SHARED + '.buttons.add' | translate}} <az-loader-button [show-loading]="cargaservice"
        [color]="'var(--primario)'" [disabled]="!form.valid || cargaservice ? true : false"></az-loader-button></button>
  </mat-dialog-actions>
</form>
<div class="table-app table-app--selectrow">
  <div class="select-rowlimit">
    <p>{{ BASE_TRANSALATE + '.lengthMenuShow' | translate }}</p>
    <select class="form-control input-filter" (change)="itemsPerPageChanged($event)">
      <option *ngFor="let option of options" [value]="option.value" [selected]="option.selected">
        {{ option.label }}
      </option>
    </select>
    <p>{{ BASE_TRANSALATE + '.lengthMenuEntries' | translate }}</p>
  </div>
</div>

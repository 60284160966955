import { Component, Input, OnInit } from "@angular/core";
import { JsonResult } from "src/app/models/json-result.model";
import { GrupoServices } from "src/app/services/grupo.service";

@Component({
    selector: 'az-adherencia-dia',
    templateUrl: './adherencia-dia.component.html'
})
export class AzAdherenciaDiaComponent implements OnInit {
    public readonly BASE_TRANSLATE = "dashboard";
    
    @Input() idprogram: number = 0;
    info: any = {
        c49: 0,
        c50_75: 0,
        c76: 0
    };

    loading: boolean = false;

    constructor(
        private grupoService: GrupoServices
    ) {}

    ngOnInit(): void {
        this.getAdherenciaDia();
    }

    getAdherenciaDia() {
        this.loading = true;
        this.grupoService.getAdherenciaDia(this.idprogram).subscribe((trae: JsonResult) => {
            this.info = trae.retObject;
            this.loading = false;
        }, error => {
            console.log(error);
            this.loading = false;
        });
    }
}
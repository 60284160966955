import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgEventBus } from 'ng-event-bus';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css'],
  providers: [TranslateService]
})
export class LanguageSelectorComponent implements OnInit {
  @Input("disableTitle") disableTitle: boolean = false;

  public selectLang: string = '';

  constructor(private translate: TranslateService, private eventbus: NgEventBus) {
    let langs = this.getTransLanguage();
    if (langs.length === 0) {
      translate.addLangs(['es', 'en']);
    }
  }

  public ngOnInit(): void {
    this.selectLang = localStorage.getItem(`azh_${environment.name}-language`) != null ? localStorage.getItem(`azh_${environment.name}-language`) : this.translate.currentLang;
    
    if (this.selectLang === undefined) {
      this.selectLang = this.translate.defaultLang;
      localStorage.setItem(`azh_${environment.name}-language`, this.selectLang);
    }
  }

  public setTransLanguage() {
    this.translate.use(this.selectLang);
    localStorage.setItem(`azh_${environment.name}-language`, this.selectLang);
    this.eventbus.cast('translate:change', this.selectLang);
  }

  private getTransLanguage() {
    return [...this.translate.getLangs()];
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'az-loader-base',
    templateUrl: './loader-base.component.html',
    styleUrls: ['./loader-base.component.css'],
    providers: []
})
export class LoaderBaseComponent implements OnInit {
    public readonly BASE_TRANSLATE_SHARED_LOADER = "shared.loader";
    
    @Input("show-loading") isLoading: boolean = false;
    @Input() title: string = "";

    constructor(private translate: TranslateService) {}

    ngOnInit(): void {
        this.isLoading = this.isLoading ? this.isLoading : false;
        this.title = this.title ? this.title : "";
    }
}
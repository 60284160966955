import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ColorPickerModule } from 'ngx-color-picker';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { MetasService } from './grupo/services/metas.service';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeEsCL from '@angular/common/locales/es-CL';
import { KeycloakSecurityService } from './services/keycloak-security.service';
import { TokenInterceptor } from './services/token.interceptor.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NgEventBus } from 'ng-event-bus';
import { TranslateModule } from '@ngx-translate/core';
import { translateModuleConfig } from 'src/app/translate/translateModuleConfig';

import { environment } from "src/environments/environment";

export function kcFactory(kcSecurity:KeycloakSecurityService) {
  return () => kcSecurity.init();
}
registerLocaleData(localeEsCL, 'es-CL');
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
  
    TranslateModule.forRoot(translateModuleConfig),

    ColorPickerModule,
    FormsModule,
    CommonModule
  ],
  providers: [
    MetasService,
    NgEventBus,
    { provide: HTTP_INTERCEPTORS , useClass: TokenInterceptor , multi : true},
    { provide: APP_INITIALIZER, deps:[KeycloakSecurityService], useFactory: kcFactory, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: localStorage.getItem(`azh_${environment.name}-language`) != null ? localStorage.getItem(`azh_${environment.name}-language`) === 'es' ? 'es-CL' : localStorage.getItem(`azh_${environment.name}-language`) : 'es-CL' },
    { provide: LOCALE_ID, useValue: localStorage.getItem(`azh_${environment.name}-language`) != null ? localStorage.getItem(`azh_${environment.name}-language`) === 'es' ? 'es-CL' : localStorage.getItem(`azh_${environment.name}-language`) : 'es-CL' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
<div #list [scrollTop]="list.scrollHeight"></div>
<app-navbar *ngIf="!(keycloak.is_paciente | async) && ((keycloak.is_profesional | async) || (keycloak.is_propietario | async) || (keycloak.is_asistente | async) || (keycloak.is_admin | async) || (keycloak.is_superadmin | async) || (keycloak.is_upi | async))" class="mdl-layout__header is-casting-shadow"></app-navbar>
<div *ngIf="!(keycloak.is_paciente | async) && ((keycloak.is_profesional | async) || (keycloak.is_propietario | async) || (keycloak.is_asistente | async) || (keycloak.is_admin | async) || (keycloak.is_superadmin | async) || (keycloak.is_upi | async))" id="showMenuLat" class="nav-lateral-app nav-lateral-app--collapse">
  <app-nav-lateral></app-nav-lateral>
</div>
<div *ngIf="!(keycloak.is_paciente | async) && ((keycloak.is_profesional | async) || (keycloak.is_propietario | async) || (keycloak.is_asistente | async) || (keycloak.is_admin | async) || (keycloak.is_superadmin | async) || (keycloak.is_upi | async))" class="mdl-layout__content">
  <div class="page-content">
    <router-outlet></router-outlet>
  </div>
</div>
<div *ngIf="!(keycloak.is_paciente | async) && ((keycloak.is_profesional | async) || (keycloak.is_propietario | async) || (keycloak.is_asistente | async) || (keycloak.is_admin | async) || (keycloak.is_superadmin | async) || (keycloak.is_upi | async))" id="menuHidden" class="mdl-layout__obfuscator"></div>

<div *ngIf="(keycloak.is_paciente | async) && (!(keycloak.is_profesional | async) || !(keycloak.is_propietario | async) || !(keycloak.is_asistente | async) || !(keycloak.is_admin | async) || !(keycloak.is_superadmin | async) || !(keycloak.is_upi | async))" class="wrapper-no-access">
  <img src="assets/img/img_acceso_denegado.svg" class="img-no-access">
  <h1 class="title-app">{{ BASE_TRANSLATE_SHARED + '.othors.t_1' | translate }}</h1>
  <p class="parrafo-app">{{ BASE_TRANSLATE_SHARED + '.othors.t_2' | translate }} {{ timeout }}s</p>
</div>
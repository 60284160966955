import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gruposMedicos'
})

export class gruposMedicosPipe implements PipeTransform {
    transform(value: string): string {
        return value.substr(0, 3).concat('-');
    }
}

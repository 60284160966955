import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router
  ) { }

  HAS_ACCESS: any;
  canActivate = (route: ActivatedRouteSnapshot) => {
    if (localStorage.getItem(`azh_${environment.name}_roles_from_support`)) {
      let a: any[] = JSON.parse(localStorage.getItem(`azh_${environment.name}_roles_from_support`));
      let b: string[] = []
      for (const i of a) {
        b.push(i.nombre)
      }
      this.HAS_ACCESS = b
    } else {
      this.HAS_ACCESS = JSON.parse(localStorage.getItem(`azh_${environment.name}_roles`) || null) || [];
    }
    let next = false;
    const ROLES = route.data.roles;

    if (!ROLES || ROLES.length === 0) {
      next = true;
    } else {
      for (const r of ROLES) {
        if (this.HAS_ACCESS.indexOf(r) > -1) {
          next = true;
          break;
        }
      }
    }

    if (next) {
      return true;
    } else {
      this.router.navigateByUrl('/inicio');
      return false;
    }
  }
}

<p class="title" [class]="loading ? 'az-skeleton' : ''">
    {{ BASE_TRANSLATE + '.widget_cars_programas.text_adherencia_al_dia' | translate }}
    <span az-tooltip class="icon-info tooltip-dark fas fa-info-circle">
        <az-tooltip [azTooltipText]="(BASE_TRANSLATE + '.widget_cars_programas.text_tooltip_adherencia_dia' | translate)" azTooltipPosition="top"></az-tooltip>
    </span>
</p>

<div class="graph-circle">
    <div class="item c-49" [class]="loading ? 'az-skeleton az-skeleton-x-3 mh-24' : ''">
        <p class="text"><span class="fas fa-user"></span> {{ info?.c49 }}</p>
    </div>
    <div class="item c-50-75" [class]="loading ? 'az-skeleton az-skeleton-x-3 mh-24' : ''">
        <p class="text"><span class="fas fa-user"></span> {{ info?.c50_75 }}</p>
    </div>
    <div class="item c-76" [class]="loading ? 'az-skeleton az-skeleton-x-3 mh-24' : ''">
        <p class="text"><span class="fas fa-user"></span> {{ info?.c76 }}</p>
    </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
   name: 'edadCAS'
})
export class EdadCasPipe implements PipeTransform {
   transform(value: string, utcMilli?: boolean): string {
      let birthday: any;
      let diffDuration: any;
      let ageString: string;
      if (value !== undefined && value !== null) {
         birthday = utcMilli ? new Date(parseInt(value)) : moment(value).format();
         diffDuration = moment.duration(moment().diff(birthday));
         ageString = ( diffDuration.years() > 0 ) ?
         `${diffDuration.years()}#{anno} ${diffDuration.months()}m` :
         `${diffDuration.months()}m ${diffDuration.days()}d`;
         return ageString;

      } else if (value === null) {
         return '---';
      }
      else {
         return 'Formato No Válido';
      }
   }
}

import { LiveAnnouncer } from '@angular/cdk/a11y';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Overlay } from '@angular/cdk/overlay';
import { Inject, Injectable, Injector } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService extends MatSnackBar {
  constructor(
    private snackBar: MatSnackBar,
    overlay: Overlay,
    injector: Injector,
    breakpointObserver: BreakpointObserver,
    liveAnnouncer: LiveAnnouncer,
    @Inject(MAT_SNACK_BAR_DEFAULT_OPTIONS) config: MatSnackBarConfig
  ) {
    super(overlay, liveAnnouncer, injector, breakpointObserver, snackBar, config);
  }

  private message(text: string, type: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.panelClass = type;
    config.data = { type, text };
    this.snackBar.open(text, '', config);
  }

  success(text: string) {
    this.message(text, 'success');
  }

  info(text: string) {
    this.message(text, 'info');
  }

  warn(text: string) {
    this.message(text, 'warn');
  }

  error(text: string) {
    this.message(text, 'error');
  }
}
import { TranslateModuleConfig } from '@ngx-translate/core';

import { translateLoaderProvider } from './translateLoaderProvider';
import { TranslateSettings } from './TranslateSettings';

import { environment } from 'src/environments/environment';

const lastLanguage: string = localStorage.getItem(`azh_${environment.name}-language`);
console.log("lastLanguage: ", lastLanguage);

export const translateModuleConfig: TranslateModuleConfig = {
  // compiler: undefined,
  defaultLanguage: lastLanguage != null ? lastLanguage : TranslateSettings.DEFAULT_LANGUAGE,
  // extend: undefined,
  // isolate: undefined,
  loader: translateLoaderProvider,
  // missingTranslationHandler: undefined,
  // parser: undefined,
  useDefaultLang: TranslateSettings.USE_DEFAULT_LANG,
};

import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Output, Renderer2, ViewChild } from '@angular/core';
import { UploadFileService } from 'src/app/admin/services/upload-file.service';
import { environment } from 'src/environments/environment';
import { AngularEditorService } from './angular-editor.service';
import { CustomClass, Font } from './config';

@Component({
  selector: 'app-angular-editor-toolbar',
  templateUrl: './angular-editor-toolbar.component.html',
  styleUrls: ['./angular-editor-styles.css']
})

export class AngularEditorToolbarComponent {
  id = '';
  htmlMode = false;
  showToolbar = true;

  block = 'default';
  defaultFontId: any;
  fontId = 0;
  fontSize = '5';
  fonts: Font[];

  customClassId = -1;
  customClasses: CustomClass[];

  tagMap = {
    BLOCKQUOTE: 'indent',
    A: 'link'
  };

  select = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'P', 'PRE', 'DIV'];

  buttons = ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'justifyLeft', 'justifyCenter',
    'justifyRight', 'justifyFull', 'indent', 'outdent', 'insertUnorderedList', 'insertOrderedList', 'link'];

  @Output() execute: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('fileInput', { static: true }) myInputFile: ElementRef;

  constructor(private _renderer: Renderer2, private uploadService: UploadFileService,
    private editorService: AngularEditorService, @Inject(DOCUMENT) private _document: any) {
  }

  triggerCommand(command: string) {
    this.execute.emit(command);
  }

  triggerButtons() {
    if (!this.showToolbar) {
      return;
    }
    this.buttons.forEach(e => {
      const result = this._document.queryCommandState(e);
      const elementById = this._document.getElementById(e + '-' + this.id);
      if (result) {
        this._renderer.addClass(elementById, 'active');
      } else {
        this._renderer.removeClass(elementById, 'active');
      }
    });
  }

  triggerBlocks(nodes: Node[]) {
    if (!this.showToolbar) {
      return;
    }
    let found = false;
    this.select.forEach(y => {
      const node = nodes.find(x => x.nodeName === y);
      if (node !== undefined && y === node.nodeName) {
        if (found === false) {
          this.block = node.nodeName.toLowerCase();
          found = true;
        }
      } else if (found === false) {
        this.block = 'default';
      }
    });

    found = false;
    if (this.fonts) {
      this.fonts.forEach((y, index) => {
        const node = nodes.find(x => {
          if (x instanceof HTMLFontElement) {
            return x.face === y.name;
          }
        });
        if (node !== undefined) {
          if (found === false) {
            this.fontId = index;
            found = true;
          }
        } else if (found === false) {
          this.fontId = this.defaultFontId;
        }
      });
    }

    found = false;
    if (this.customClasses) {
      this.customClasses.forEach((y, index) => {
        const node = nodes.find(x => {
          if (x instanceof Element) {
            return x.className === y.class;
          }
        });
        if (node !== undefined) {
          if (found === false) {
            this.customClassId = index;
            found = true;
          }
        } else if (found === false) {
          this.customClassId = -1;
        }
      });
    }

    Object.keys(this.tagMap).forEach(e => {
      const elementById = this._document.getElementById(this.tagMap[e] + '-' + this.id);
      const node = nodes.find(x => x.nodeName === e);
      if (node !== undefined && e === node.nodeName) {
        this._renderer.addClass(elementById, 'active');
      } else {
        this._renderer.removeClass(elementById, 'active');
      }
    });
  }

  insertUrl() {
    const url = prompt('Ingrese URL', 'http:\/\/');
    if (url && url !== '' && url !== 'http://') {
      this.editorService.createLink(url);
    }
  }

  insertColor(color: string, where: string) {
    this.editorService.insertColor(color, where);
    this.execute.emit('');
  }

  setFontName(fontId: number): void {
    this.editorService.setFontName(this.fonts[fontId].name);
    this.execute.emit('');
  }

  setFontSize(fontSize: string): void {
    this.editorService.setFontSize(fontSize);
    this.execute.emit('');
  }

  setEditorMode(m: boolean) {
    const toggleEditorModeButton = this._document.getElementById('toggleEditorMode' + '-' + this.id);
    if (m) {
      this._renderer.addClass(toggleEditorModeButton, 'active');
    } else {
      this._renderer.removeClass(toggleEditorModeButton, 'active');
    }
    this.htmlMode = m;
  }

  onFileChanged(event) {
    const file = event.target.files[0];
    if (file.type.includes('image/')) {
      try {
        this.uploadIMG(file, environment.S3_DIR_IMG_EMAIL_ADJUNTOS);
      } catch (error) {
        console.error('Error en el retorno del servicio', error.message);
      }
    }
  }

  uploadIMG(file: File, foldername: string) {
    this.uploadService.getUploadURL(foldername).subscribe((res: any) => {
      if (res.success) {
        const fileuploadurl = res.urls[0];
        this.uploadService.uploadfileAWSS3(fileuploadurl, file.type, file).subscribe(() => {
          this.editorService.insertImage(fileuploadurl.split('?')[0]);
          this.fileReset();
        }, err => {
          console.log('error uploading ', err);
        });
      }
    });
  }

  fileReset() {
    this.myInputFile.nativeElement.value = '';
  }

  setCustomClass(classId: number) {
    this.editorService.createCustomClass(this.customClasses[classId]);
  }
}

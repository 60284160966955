import { TableFieldType } from './table-field-type.enum';

export class TableField {
  public key: string = '';
  public label: string = '';
  public translation?: string;
  public sortable?: boolean;
  public editable?: boolean;
  public class?: string | Array<string>;
  public tdClass?: string | Array<string>;
  public maxlength?: number;
  public thClass?: string | Array<string>;

  public inputType?: TableFieldType;
  public inputMask?: string;
  public inputRequired?: boolean;
  public readonly?: boolean;

  public html?: boolean;
  public link?: boolean;
  public eventEmit?: boolean;
  public exportable?: boolean;
  public width?: string | Array<string>;
  public textAlign?: string | Array<string>;
}

<ng-template #defaultBodyTemplate>
  <ng-container *ngIf="rowsList.length > 0; else elseEmptyTemplate">
    <ng-container *ngFor="let row of rowsList; let i = index">
      <ng-container *ngIf="scrollable; else notScrollableBodyTemplate">
        <div class="tr">
          <ng-container *ngIf="selectable">
            <ng-container *ngIf="isCheckbox(row); else noCheckbox">
              <div class="td checkbox" az-table-cell-checkbox [checked]="row.selected" [selectMode]="selectMode"
                [idTable]="idTable" (changeSelection)="$event ? selectRow(row, i) : unselectRow(row, i)"></div>
            </ng-container>
            <ng-template #noCheckbox>
              <div class="td checkbox"></div>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="(fields ? fields : []).length > 0; else noFieldDefinitionTemplate">
            <ng-container *ngFor="let field of fields">
              <div class="td" [ngClass]="getCellClass(field)" [ngStyle]="getStyles(field)">
                <ng-container *ngIf="getIsEditable(field); else notEditableTemplate">
                  <az-table-cell-editable [field]="field" [row]="row" [vInput]="getCellValue(row.item, field)"
                    (change)="onChangeTableEditable($event, row.item, field)"></az-table-cell-editable>
                </ng-container>
                <ng-template #notEditableTemplate>
                  <ng-container *ngIf="getHTMLBoolean(field); else noHTML">
                    <div class="html"
                      (click)="geLINKBoolean(field, row.item) ? goLink(field, row.item):geEmitBoolean(field, row.item) ?goEmit(field, row.item):''"
                      [innerHTML]="getCellValue(row.item, field) | safeHtml"></div>
                  </ng-container>

                  <ng-template #noHTML>
                    {{ getCellValue(row.item, field) }}
                  </ng-template>
                </ng-template>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #noFieldDefinitionTemplate>
            <ng-container *ngFor="let property of getProperties(row.item)">
              <div class="td">
                {{ property.value }}
              </div>
            </ng-container>
          </ng-template>

          <ng-container *ngIf="actionable">
            <div class="td">
              <div class="tools" az-table-cell-actions [actions]="actions" [row]="row"
                (actionClick)="actionClicked($event, row)"></div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #notScrollableBodyTemplate>
        <tr>
          <ng-container *ngIf="selectable">
            <ng-container *ngIf="isCheckbox(row); else noCheckbox">
              <th class="checkbox" az-table-cell-checkbox [checked]="row.selected" [selectMode]="selectMode"
                [idTable]="idTable" scope="col" (changeSelection)="$event ? selectRow(row, i) : unselectRow(row, i)">
              </th>
            </ng-container>
            <ng-template #noCheckbox>
              <th class="checkbox" scope="col"></th>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="(fields ? fields : []).length > 0; else noFieldDefinitionTemplate">
            <ng-container *ngFor="let field of fields">
              <td [ngClass]="getCellClass(field)" [ngStyle]="getStyles(field)">
                <ng-container *ngIf="getIsEditable(field); else notEditableTemplate">
                  <az-table-cell-editable [field]="field" [row]="row" [vInput]="getCellValue(row.item, field)"
                    (change)="onChangeTableEditable($event, row.item, field)"></az-table-cell-editable>
                </ng-container>
                <ng-template #notEditableTemplate>
                  <ng-container *ngIf="getHTMLBoolean(field); else noHTML">
                    <div class="html"
                      (click)="geLINKBoolean(field, row.item) ? goLink(field, row.item):geEmitBoolean(field, row.item) ?goEmit(field, row.item):''"
                      [innerHTML]="getCellValue(row.item, field) | safeHtml"></div>
                  </ng-container>
                  <ng-template #noHTML>
                    {{ getCellValue(row.item, field) }}
                  </ng-template>
                </ng-template>
              </td>
            </ng-container>
          </ng-container>
          <ng-template #noFieldDefinitionTemplate>
            <ng-container *ngFor="let property of getProperties(row.item)">
              <td>
                {{ property.value }}
              </td>
            </ng-container>
          </ng-template>

          <ng-container *ngIf="actionable">
            <td>
              <div class="tools" az-table-cell-actions [actions]="actions" [row]="row"
                (actionClick)="actionClicked($event, row)"></div>
            </td>
          </ng-container>
        </tr>
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-template #elseEmptyTemplate>
    <ng-container
      *ngTemplateOutlet="emptyTemplate ? emptyTemplate : !scrollable ? defaultEmptyTemplateTable : defaultEmptyTemplateDiv">
    </ng-container>
  </ng-template>
</ng-template>

<ng-container *ngTemplateOutlet="bodyTemplate ? bodyTemplate : defaultBodyTemplate"></ng-container>

<ng-template #defaultEmptyTemplateTable>
  <tr class="no-data-available text-center">
    <td [attr.colspan]="getColspan()" class="no-data-available">
      <svg class="not-data-img" version="1.1" id="_x31_" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 433.4 230.1"
        style="enable-background:new 0 0 433.4 230.1;" xml:space="preserve">
        <defs>
          <style type="text/css">
            :root {
              --color-base: var(--primario);
            }

            .st0 {
              fill: transparent;
            }

            .st1 {
              fill: var(--color-base);
            }

            .st2 {
              fill: url(#SVGID_1_);
            }

            .st3 {
              fill: url(#SVGID_2_);
            }

            .st4 {
              fill: var(--color-base);
            }

            .st5 {
              fill: #FFFFFF;
            }

            .st6 {
              fill: url(#SVGID_3_);
            }

            .st7 {
              fill: url(#SVGID_5_);
            }

            .st8 {
              fill: url(#SVGID_6_);
            }

            .st9 {
              fill: url(#SVGID_7_);
            }

            .st10 {
              fill: url(#SVGID_8_);
            }

            .st11 {
              fill: url(#SVGID_9_);
            }

            .st12 {
              fill: url(#SVGID_10_);
            }

            .st13 {
              fill: #1E70CD;
            }

            .st14 {
              opacity: 0.75;
            }

            .st15 {
              fill: #ADC8FF;
            }

            .st16 {
              fill: url(#SVGID_11_);
            }

            .st17 {
              fill: #91AFFF;
            }

            .st18 {
              fill: url(#SVGID_12_);
            }

            .st19 {
              opacity: 0.11;
            }

            .st20 {
              clip-path: url(#SVGID_13_);
            }

            .st21 {
              fill: url(#SVGID_14_);
            }

            .st22 {
              fill: url(#SVGID_15_);
            }

            .st23 {
              fill: #242993;
            }

            .st24 {
              opacity: 0.15;
              fill: url(#SVGID_16_);
            }

            .st25 {
              opacity: 0.15;
              fill: url(#SVGID_17_);
            }

            .st26 {
              opacity: 0.5;
              fill: #1E70CD;
            }

            .st27 {
              fill: url(#SVGID_18_);
            }

            .st28 {
              fill: url(#SVGID_19_);
            }

            .st29 {
              opacity: 0.6;
              fill: #242993;
            }

            .st30 {
              fill: #4553C7;
            }

            .st31 {
              opacity: 0.96;
              fill: url(#SVGID_20_);
            }

            .st32 {
              opacity: 0.8;
              fill: #4553C7;
            }

            .st33 {
              fill: #9AA3B5;
            }

            .st34 {
              fill: #4F5255;
            }

            .st35 {
              fill: #D4D4D5;
            }

            .st36 {
              fill: #CCCCCC;
            }

            .animate-zoom {
              transform-origin: 50% 50%;
              opacity: 0;
              animation: opacity 2s infinite alternate;
            }

            .animate-zoom--1 {
              animation-duration: 1.5s;
            }

            .animate-zoom--2 {
              animation-duration: 2.5s;
            }

            .animate-zoom--3 {
              animation-duration: 1.5s;
            }

            .animate-zoom--4 {
              animation-duration: 1.1s;
            }

            .animate-zoom--5 {
              animation-duration: 1.68s;
            }

            .animate-zoom--6 {
              animation-duration: 2s;
            }

            .animate-zoom--7 {
              animation-duration: 1s;
            }

            .animate-zoom--8 {
              animation-duration: 1.35s;
            }

            .animate-zoom--9 {
              animation-duration: 1.95s;
            }

            .animate-campaneo {
              transform-origin: 50% 0;
              transform: rotate(0deg);
              animation: campana 1.5s ease-in-out infinite alternate;
            }

            @keyframes opacity {
              from {
                opacity: 0;
              }

              to {
                opacity: 1;
              }
            }
          </style>
        </defs>
        <ellipse class="st0" cx="216.7" cy="115" rx="201.6" ry="96.1" />
        <path class="st36" d="M216.7,211.2c30.6,0,59.5-3.2,85.5-9.1c-22.8-4.8-52-7.7-83.9-7.7c-32.7,0-62.5,3-85.5,8.1
        C158.3,208.1,186.7,211.2,216.7,211.2z" />
        <path class="st36 animate-zoom animate-zoom--2" d="M103.1,76.2h-3.6c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9h3.6c1,0,1.9-0.8,1.9-1.9
          C105,77,104.1,76.2,103.1,76.2z M115.8,76.2h-3.6c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9h3.6c1,0,1.9-0.8,1.9-1.9
          C117.7,77,116.9,76.2,115.8,76.2z M107.7,80.8c-1,0-1.9,0.8-1.9,1.9v3.6c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9v-3.6
          C109.5,81.6,108.7,80.8,107.7,80.8z M107.7,68c-1,0-1.9,0.8-1.9,1.9v3.6c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9v-3.6
          C109.5,68.8,108.7,68,107.7,68z" />
        <path class="st36 animate-zoom animate-zoom--3" d="M72.9,149.8h-5c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6h5c1.4,0,2.6-1.2,2.6-2.6S74.4,149.8,72.9,149.8z
          M90.7,149.8h-5c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6h5c1.4,0,2.6-1.2,2.6-2.6S92.1,149.8,90.7,149.8z M79.3,156.2
          c-1.4,0-2.6,1.2-2.6,2.6v5c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6v-5C81.9,157.3,80.7,156.2,79.3,156.2z M79.3,138.4
          c-1.4,0-2.6,1.2-2.6,2.6v5c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6v-5C81.9,139.6,80.7,138.4,79.3,138.4z" />
        <path class="st36 animate-zoom animate-zoom--4" d="M375.9,68.5h-5c-1.4,0-2.6,1.2-2.6,2.6c0,1.4,1.2,2.6,2.6,2.6h5c1.4,0,2.6-1.2,2.6-2.6
          C378.5,69.7,377.3,68.5,375.9,68.5z M393.6,68.5h-5c-1.4,0-2.6,1.2-2.6,2.6c0,1.4,1.2,2.6,2.6,2.6h5c1.4,0,2.6-1.2,2.6-2.6
          C396.2,69.7,395,68.5,393.6,68.5z M382.2,74.9c-1.4,0-2.6,1.2-2.6,2.6v5c0,1.4,1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6v-5
          C384.8,76,383.7,74.9,382.2,74.9z M382.2,57.1c-1.4,0-2.6,1.2-2.6,2.6v5c0,1.4,1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6v-5
          C384.8,58.3,383.7,57.1,382.2,57.1z" />
        <path class="st36 animate-zoom animate-zoom--5" d="M27.1,90.2h-5c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6h5c1.4,0,2.6-1.2,2.6-2.6S28.5,90.2,27.1,90.2z
          M44.8,90.2h-5c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6h5c1.4,0,2.6-1.2,2.6-2.6S46.3,90.2,44.8,90.2z M33.5,96.5
          c-1.4,0-2.6,1.2-2.6,2.6v5c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6v-5C36,97.7,34.9,96.5,33.5,96.5z M33.5,78.8
          c-1.4,0-2.6,1.2-2.6,2.6v5c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6v-5C36,79.9,34.9,78.8,33.5,78.8z" />
        <path class="st36 animate-zoom animate-zoom--6"
          d="M349.6,140.3h-6.3c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3h6.3c1.8,0,3.3-1.5,3.3-3.3S351.4,140.3,349.6,140.3
          z M372,140.3h-6.3c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3h6.3c1.8,0,3.3-1.5,3.3-3.3S373.8,140.3,372,140.3z M357.7,148.3
          c-1.8,0-3.3,1.5-3.3,3.3v6.3c0,1.8,1.5,3.3,3.3,3.3s3.3-1.5,3.3-3.3v-6.3C360.9,149.7,359.5,148.3,357.7,148.3z M357.7,125.9
          c-1.8,0-3.3,1.5-3.3,3.3v6.3c0,1.8,1.5,3.3,3.3,3.3s3.3-1.5,3.3-3.3v-6.3C360.9,127.4,359.5,125.9,357.7,125.9z" />
        <path class="st36 animate-zoom animate-zoom--7" d="M315.7,93.4h-2.8c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4h2.8c0.8,0,1.4-0.6,1.4-1.4
          C317.2,94,316.5,93.4,315.7,93.4z M325.6,93.4h-2.8c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4h2.8c0.8,0,1.4-0.6,1.4-1.4
          C327,94,326.4,93.4,325.6,93.4z M319.3,96.9c-0.8,0-1.4,0.6-1.4,1.4v2.8c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4v-2.8
          C320.7,97.6,320.1,96.9,319.3,96.9z M319.3,87.1c-0.8,0-1.4,0.6-1.4,1.4v2.8c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4v-2.8
          C320.7,87.7,320.1,87.1,319.3,87.1z" />
        <path class="st36 animate-zoom animate-zoom--8" d="M290.9,24.1h-4.1c-1.2,0-2.1,1-2.1,2.1c0,1.2,1,2.1,2.1,2.1h4.1c1.2,0,2.1-1,2.1-2.1
          C293,25,292,24.1,290.9,24.1z M305.5,24.1h-4.1c-1.2,0-2.1,1-2.1,2.1c0,1.2,1,2.1,2.1,2.1h4.1c1.2,0,2.1-1,2.1-2.1
          C307.6,25,306.7,24.1,305.5,24.1z M296.1,29.3c-1.2,0-2.1,1-2.1,2.1v4.1c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1v-4.1
          C298.3,30.3,297.3,29.3,296.1,29.3z M296.1,14.7c-1.2,0-2.1,1-2.1,2.1V21c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1v-4.1
          C298.3,15.6,297.3,14.7,296.1,14.7z" />
        <path class="st36 animate-zoom animate-zoom--9" d="M118,117.6h-2.6c-0.7,0-1.4,0.6-1.4,1.4c0,0.7,0.6,1.4,1.4,1.4h2.6c0.7,0,1.4-0.6,1.4-1.4
          C119.4,118.2,118.8,117.6,118,117.6z M127.3,117.6h-2.6c-0.7,0-1.4,0.6-1.4,1.4c0,0.7,0.6,1.4,1.4,1.4h2.6c0.7,0,1.4-0.6,1.4-1.4
          C128.7,118.2,128.1,117.6,127.3,117.6z M121.4,121c-0.7,0-1.4,0.6-1.4,1.4v2.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4v-2.6
          C122.7,121.6,122.1,121,121.4,121z M121.4,111.7c-0.7,0-1.4,0.6-1.4,1.4v2.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4V113
          C122.7,112.3,122.1,111.7,121.4,111.7z" />
        <g>
          <path id="XMLID_581_" class="st1" d="M247.4,57.9h-61.3c-1.7,0-3.1,1.4-3.1,3.1v22.1c0,1.7,1.4,3.1,3.1,3.1h61.3
            c1.7,0,3.1-1.4,3.1-3.1V61C250.4,59.3,249.1,57.9,247.4,57.9z M244.3,80h-55.1V64.1h55.1V80z" />
          <path id="XMLID_585_" class="st1" d="M186.1,103.3h61.3c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1h-61.3c-1.7,0-3.1,1.4-3.1,3.1
            C183,101.9,184.4,103.3,186.1,103.3z" />
          <path id="XMLID_587_" class="st1" d="M247.4,114.3h-61.3c-1.7,0-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1h61.3c1.7,0,3.1-1.4,3.1-3.1
            S249,114.3,247.4,114.3z" />
          <path class="st34" d="M242.1,154.8c-1.7,0-3.1,1.4-3.1,3.1v6h-44.7v-6c0-1.7-1.4-3.1-3.1-3.1c-1.7,0-3.1,1.4-3.1,3.1v9.1
            c0,1.7,1.4,3.1,3.1,3.1h50.9c1.7,0,3.1-1.4,3.1-3.1v-9.1C245.2,156.2,243.8,154.8,242.1,154.8z" />
          <path class="st34" d="M294.7,138.5C294.7,138.5,294.7,138.5,294.7,138.5l-19.9-35.2c-0.5-1-1.6-1.6-2.7-1.6h-4.6V52.4
            c0-5.1-4.1-9.2-9.2-9.2h-0.6v-3.1c0-5.1-4.1-9.2-9.2-9.2h-63.7c-5.1,0-9.2,4.1-9.2,9.2v3.1H175c-5.1,0-9.2,4.1-9.2,9.2v49.4h-4.5
            c-1.1,0-2.1,0.6-2.7,1.6l-20,35.2c0,0,0,0,0,0c-0.3,0.4-0.4,1-0.4,1.5v44.7c0,1.7,1.4,3.1,3.1,3.1h9.1h5h24.5h22.5h89.7
            c1.7,0,3.1-1.4,3.1-3.1v-44.7C295.1,139.5,295,139,294.7,138.5z M267.6,107.9h2.8l16.4,29.1h-19.3V107.9z M181.7,40.2
            c0-1.7,1.4-3.1,3.1-3.1h63.7c1.7,0,3.1,1.4,3.1,3.1v3.1h-69.9V40.2z M171.9,52.4c0-1.7,1.4-3.1,3.1-3.1h83.4c1.7,0,3.1,1.4,3.1,3.1
            V137h-14.6h-20.6c-1.7,0-3.1,1.4-3.1,3.1c0,3.5-2.9,6.4-6.4,6.4c-3.5,0-6.4-2.9-6.4-6.4c0-1.7-1.4-3.1-3.1-3.1h-13.3h-22V52.4z
            M163.1,107.9h2.8V137h-19.3L163.1,107.9z M289,181.7h-86.7h-22.5h-24.5h-5h-6v-38.6h49.5h10.6c1.4,5.5,6.3,9.5,12.2,9.5
            c5.9,0,10.8-4,12.2-9.5h17.9H289V181.7z" />
        </g>
      </svg>
      <p class="m-0">{{ BASE_TRANSALATE + '.no_records_available' | translate }}</p>
    </td>
  </tr>
</ng-template>

<ng-template #defaultEmptyTemplateDiv>
  <div class="tr no-data-available">
    <div class="td no-data-available">
      <svg class="not-data-img" version="1.1" id="_x31_" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 433.4 230.1"
        style="enable-background:new 0 0 433.4 230.1;" xml:space="preserve">
        <defs>
          <style type="text/css">
            :root {
              --color-base: var(--primario);
            }

            .st0 {
              fill: transparent;
            }

            .st1 {
              fill: var(--color-base);
            }

            .st2 {
              fill: url(#SVGID_1_);
            }

            .st3 {
              fill: url(#SVGID_2_);
            }

            .st4 {
              fill: var(--color-base);
            }

            .st5 {
              fill: #FFFFFF;
            }

            .st6 {
              fill: url(#SVGID_3_);
            }

            .st7 {
              fill: url(#SVGID_5_);
            }

            .st8 {
              fill: url(#SVGID_6_);
            }

            .st9 {
              fill: url(#SVGID_7_);
            }

            .st10 {
              fill: url(#SVGID_8_);
            }

            .st11 {
              fill: url(#SVGID_9_);
            }

            .st12 {
              fill: url(#SVGID_10_);
            }

            .st13 {
              fill: #1E70CD;
            }

            .st14 {
              opacity: 0.75;
            }

            .st15 {
              fill: #ADC8FF;
            }

            .st16 {
              fill: url(#SVGID_11_);
            }

            .st17 {
              fill: #91AFFF;
            }

            .st18 {
              fill: url(#SVGID_12_);
            }

            .st19 {
              opacity: 0.11;
            }

            .st20 {
              clip-path: url(#SVGID_13_);
            }

            .st21 {
              fill: url(#SVGID_14_);
            }

            .st22 {
              fill: url(#SVGID_15_);
            }

            .st23 {
              fill: #242993;
            }

            .st24 {
              opacity: 0.15;
              fill: url(#SVGID_16_);
            }

            .st25 {
              opacity: 0.15;
              fill: url(#SVGID_17_);
            }

            .st26 {
              opacity: 0.5;
              fill: #1E70CD;
            }

            .st27 {
              fill: url(#SVGID_18_);
            }

            .st28 {
              fill: url(#SVGID_19_);
            }

            .st29 {
              opacity: 0.6;
              fill: #242993;
            }

            .st30 {
              fill: #4553C7;
            }

            .st31 {
              opacity: 0.96;
              fill: url(#SVGID_20_);
            }

            .st32 {
              opacity: 0.8;
              fill: #4553C7;
            }

            .st33 {
              fill: #9AA3B5;
            }

            .st34 {
              fill: #4F5255;
            }

            .st35 {
              fill: #D4D4D5;
            }

            .st36 {
              fill: #CCCCCC;
            }

            .animate-zoom {
              transform-origin: 50% 50%;
              opacity: 0;
              animation: opacity 2s infinite alternate;
            }

            .animate-zoom--1 {
              animation-duration: 1.5s;
            }

            .animate-zoom--2 {
              animation-duration: 2.5s;
            }

            .animate-zoom--3 {
              animation-duration: 1.5s;
            }

            .animate-zoom--4 {
              animation-duration: 1.1s;
            }

            .animate-zoom--5 {
              animation-duration: 1.68s;
            }

            .animate-zoom--6 {
              animation-duration: 2s;
            }

            .animate-zoom--7 {
              animation-duration: 1s;
            }

            .animate-zoom--8 {
              animation-duration: 1.35s;
            }

            .animate-zoom--9 {
              animation-duration: 1.95s;
            }

            .animate-campaneo {
              transform-origin: 50% 0;
              transform: rotate(0deg);
              animation: campana 1.5s ease-in-out infinite alternate;
            }

            @keyframes opacity {
              from {
                opacity: 0;
              }

              to {
                opacity: 1;
              }
            }
          </style>
        </defs>
        <ellipse class="st0" cx="216.7" cy="115" rx="201.6" ry="96.1" />
        <path class="st36" d="M216.7,211.2c30.6,0,59.5-3.2,85.5-9.1c-22.8-4.8-52-7.7-83.9-7.7c-32.7,0-62.5,3-85.5,8.1
        C158.3,208.1,186.7,211.2,216.7,211.2z" />
        <path class="st36 animate-zoom animate-zoom--2" d="M103.1,76.2h-3.6c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9h3.6c1,0,1.9-0.8,1.9-1.9
          C105,77,104.1,76.2,103.1,76.2z M115.8,76.2h-3.6c-1,0-1.9,0.8-1.9,1.9c0,1,0.8,1.9,1.9,1.9h3.6c1,0,1.9-0.8,1.9-1.9
          C117.7,77,116.9,76.2,115.8,76.2z M107.7,80.8c-1,0-1.9,0.8-1.9,1.9v3.6c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9v-3.6
          C109.5,81.6,108.7,80.8,107.7,80.8z M107.7,68c-1,0-1.9,0.8-1.9,1.9v3.6c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9v-3.6
          C109.5,68.8,108.7,68,107.7,68z" />
        <path class="st36 animate-zoom animate-zoom--3" d="M72.9,149.8h-5c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6h5c1.4,0,2.6-1.2,2.6-2.6S74.4,149.8,72.9,149.8z
          M90.7,149.8h-5c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6h5c1.4,0,2.6-1.2,2.6-2.6S92.1,149.8,90.7,149.8z M79.3,156.2
          c-1.4,0-2.6,1.2-2.6,2.6v5c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6v-5C81.9,157.3,80.7,156.2,79.3,156.2z M79.3,138.4
          c-1.4,0-2.6,1.2-2.6,2.6v5c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6v-5C81.9,139.6,80.7,138.4,79.3,138.4z" />
        <path class="st36 animate-zoom animate-zoom--4" d="M375.9,68.5h-5c-1.4,0-2.6,1.2-2.6,2.6c0,1.4,1.2,2.6,2.6,2.6h5c1.4,0,2.6-1.2,2.6-2.6
          C378.5,69.7,377.3,68.5,375.9,68.5z M393.6,68.5h-5c-1.4,0-2.6,1.2-2.6,2.6c0,1.4,1.2,2.6,2.6,2.6h5c1.4,0,2.6-1.2,2.6-2.6
          C396.2,69.7,395,68.5,393.6,68.5z M382.2,74.9c-1.4,0-2.6,1.2-2.6,2.6v5c0,1.4,1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6v-5
          C384.8,76,383.7,74.9,382.2,74.9z M382.2,57.1c-1.4,0-2.6,1.2-2.6,2.6v5c0,1.4,1.2,2.6,2.6,2.6c1.4,0,2.6-1.2,2.6-2.6v-5
          C384.8,58.3,383.7,57.1,382.2,57.1z" />
        <path class="st36 animate-zoom animate-zoom--5" d="M27.1,90.2h-5c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6h5c1.4,0,2.6-1.2,2.6-2.6S28.5,90.2,27.1,90.2z
          M44.8,90.2h-5c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6h5c1.4,0,2.6-1.2,2.6-2.6S46.3,90.2,44.8,90.2z M33.5,96.5
          c-1.4,0-2.6,1.2-2.6,2.6v5c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6v-5C36,97.7,34.9,96.5,33.5,96.5z M33.5,78.8
          c-1.4,0-2.6,1.2-2.6,2.6v5c0,1.4,1.2,2.6,2.6,2.6s2.6-1.2,2.6-2.6v-5C36,79.9,34.9,78.8,33.5,78.8z" />
        <path class="st36 animate-zoom animate-zoom--6"
          d="M349.6,140.3h-6.3c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3h6.3c1.8,0,3.3-1.5,3.3-3.3S351.4,140.3,349.6,140.3
          z M372,140.3h-6.3c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3h6.3c1.8,0,3.3-1.5,3.3-3.3S373.8,140.3,372,140.3z M357.7,148.3
          c-1.8,0-3.3,1.5-3.3,3.3v6.3c0,1.8,1.5,3.3,3.3,3.3s3.3-1.5,3.3-3.3v-6.3C360.9,149.7,359.5,148.3,357.7,148.3z M357.7,125.9
          c-1.8,0-3.3,1.5-3.3,3.3v6.3c0,1.8,1.5,3.3,3.3,3.3s3.3-1.5,3.3-3.3v-6.3C360.9,127.4,359.5,125.9,357.7,125.9z" />
        <path class="st36 animate-zoom animate-zoom--7" d="M315.7,93.4h-2.8c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4h2.8c0.8,0,1.4-0.6,1.4-1.4
          C317.2,94,316.5,93.4,315.7,93.4z M325.6,93.4h-2.8c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4h2.8c0.8,0,1.4-0.6,1.4-1.4
          C327,94,326.4,93.4,325.6,93.4z M319.3,96.9c-0.8,0-1.4,0.6-1.4,1.4v2.8c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4v-2.8
          C320.7,97.6,320.1,96.9,319.3,96.9z M319.3,87.1c-0.8,0-1.4,0.6-1.4,1.4v2.8c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4v-2.8
          C320.7,87.7,320.1,87.1,319.3,87.1z" />
        <path class="st36 animate-zoom animate-zoom--8" d="M290.9,24.1h-4.1c-1.2,0-2.1,1-2.1,2.1c0,1.2,1,2.1,2.1,2.1h4.1c1.2,0,2.1-1,2.1-2.1
          C293,25,292,24.1,290.9,24.1z M305.5,24.1h-4.1c-1.2,0-2.1,1-2.1,2.1c0,1.2,1,2.1,2.1,2.1h4.1c1.2,0,2.1-1,2.1-2.1
          C307.6,25,306.7,24.1,305.5,24.1z M296.1,29.3c-1.2,0-2.1,1-2.1,2.1v4.1c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1v-4.1
          C298.3,30.3,297.3,29.3,296.1,29.3z M296.1,14.7c-1.2,0-2.1,1-2.1,2.1V21c0,1.2,1,2.1,2.1,2.1c1.2,0,2.1-1,2.1-2.1v-4.1
          C298.3,15.6,297.3,14.7,296.1,14.7z" />
        <path class="st36 animate-zoom animate-zoom--9" d="M118,117.6h-2.6c-0.7,0-1.4,0.6-1.4,1.4c0,0.7,0.6,1.4,1.4,1.4h2.6c0.7,0,1.4-0.6,1.4-1.4
          C119.4,118.2,118.8,117.6,118,117.6z M127.3,117.6h-2.6c-0.7,0-1.4,0.6-1.4,1.4c0,0.7,0.6,1.4,1.4,1.4h2.6c0.7,0,1.4-0.6,1.4-1.4
          C128.7,118.2,128.1,117.6,127.3,117.6z M121.4,121c-0.7,0-1.4,0.6-1.4,1.4v2.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4v-2.6
          C122.7,121.6,122.1,121,121.4,121z M121.4,111.7c-0.7,0-1.4,0.6-1.4,1.4v2.6c0,0.7,0.6,1.4,1.4,1.4c0.7,0,1.4-0.6,1.4-1.4V113
          C122.7,112.3,122.1,111.7,121.4,111.7z" />
        <g>
          <path id="XMLID_581_" class="st1" d="M247.4,57.9h-61.3c-1.7,0-3.1,1.4-3.1,3.1v22.1c0,1.7,1.4,3.1,3.1,3.1h61.3
            c1.7,0,3.1-1.4,3.1-3.1V61C250.4,59.3,249.1,57.9,247.4,57.9z M244.3,80h-55.1V64.1h55.1V80z" />
          <path id="XMLID_585_" class="st1" d="M186.1,103.3h61.3c1.7,0,3.1-1.4,3.1-3.1c0-1.7-1.4-3.1-3.1-3.1h-61.3c-1.7,0-3.1,1.4-3.1,3.1
            C183,101.9,184.4,103.3,186.1,103.3z" />
          <path id="XMLID_587_" class="st1" d="M247.4,114.3h-61.3c-1.7,0-3.1,1.4-3.1,3.1s1.4,3.1,3.1,3.1h61.3c1.7,0,3.1-1.4,3.1-3.1
            S249,114.3,247.4,114.3z" />
          <path class="st34" d="M242.1,154.8c-1.7,0-3.1,1.4-3.1,3.1v6h-44.7v-6c0-1.7-1.4-3.1-3.1-3.1c-1.7,0-3.1,1.4-3.1,3.1v9.1
            c0,1.7,1.4,3.1,3.1,3.1h50.9c1.7,0,3.1-1.4,3.1-3.1v-9.1C245.2,156.2,243.8,154.8,242.1,154.8z" />
          <path class="st34" d="M294.7,138.5C294.7,138.5,294.7,138.5,294.7,138.5l-19.9-35.2c-0.5-1-1.6-1.6-2.7-1.6h-4.6V52.4
            c0-5.1-4.1-9.2-9.2-9.2h-0.6v-3.1c0-5.1-4.1-9.2-9.2-9.2h-63.7c-5.1,0-9.2,4.1-9.2,9.2v3.1H175c-5.1,0-9.2,4.1-9.2,9.2v49.4h-4.5
            c-1.1,0-2.1,0.6-2.7,1.6l-20,35.2c0,0,0,0,0,0c-0.3,0.4-0.4,1-0.4,1.5v44.7c0,1.7,1.4,3.1,3.1,3.1h9.1h5h24.5h22.5h89.7
            c1.7,0,3.1-1.4,3.1-3.1v-44.7C295.1,139.5,295,139,294.7,138.5z M267.6,107.9h2.8l16.4,29.1h-19.3V107.9z M181.7,40.2
            c0-1.7,1.4-3.1,3.1-3.1h63.7c1.7,0,3.1,1.4,3.1,3.1v3.1h-69.9V40.2z M171.9,52.4c0-1.7,1.4-3.1,3.1-3.1h83.4c1.7,0,3.1,1.4,3.1,3.1
            V137h-14.6h-20.6c-1.7,0-3.1,1.4-3.1,3.1c0,3.5-2.9,6.4-6.4,6.4c-3.5,0-6.4-2.9-6.4-6.4c0-1.7-1.4-3.1-3.1-3.1h-13.3h-22V52.4z
            M163.1,107.9h2.8V137h-19.3L163.1,107.9z M289,181.7h-86.7h-22.5h-24.5h-5h-6v-38.6h49.5h10.6c1.4,5.5,6.3,9.5,12.2,9.5
            c5.9,0,10.8-4,12.2-9.5h17.9H289V181.7z" />
        </g>
      </svg>
      <p class="m-0">{{ BASE_TRANSALATE + '.no_records_available' | translate }}</p>
    </div>
  </div>
</ng-template>
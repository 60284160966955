<div *ngIf="filteredElements.length > 0" class="mdl-grid" style="display: table-caption !important">
  <span *ngFor="let etiqueta of filteredElements">
    <mat-checkbox
      [matTooltip]="BASE_TRANSLATE_SHARED + '.validation.noetiquetamultiples' | translate"
      [matTooltipDisabled]="!(etiqueta.defaultstate === false) || !etiqueta.formulario || isbatch === false"
      [disabled]="etiqueta.formulario && etiqueta.defaultstate === false && isbatch === true"
      [ngModel]="etiqueta.currentstate" [indeterminate]="etiqueta.currentstate === null"
      (change)="onChangeCheckbox($event.checked,etiqueta.idetiqueta)"
      [color]="etiqueta.currentstate !== etiqueta.defaultstate ? 'change':'unchange'"
    >
      <span class="mdl-chip" style="margin-right: 2px" [style.background-color]="etiqueta.colorfondo">
        <span class="mdl-chip__text" [style.color]="etiqueta.colorfuente" style="vertical-align: unset !important;">
          {{ etiqueta.descripcion }}
        </span>
      </span>
      <span class="help-icons">
        <i *ngIf="etiqueta.formulario" class="far fa-ballot fa-xl" aria-hidden="true"></i>
        <i *ngIf="etiqueta.template" class="far fa-paper-plane fa-xl" aria-hidden="true"></i>
      </span>
    </mat-checkbox>
  </span>
</div>
<div *ngIf="elements.length === 0 && !loading">
  <span class="multiselect-text">{{ noElementsLabel }}</span>
</div>
<div *ngIf="elements.length > 0 && filteredElements.length === 0">
  <span class="multiselect-text">{{ BASE_TRANSLATE_SHARED + '.validation.notResult' | translate }}</span>
</div>
import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'cas-multiselect-etiqueta',
  templateUrl: './cas-multiselect-etiqueta.component.html',
  styleUrls: ['./cas-multiselect-etiqueta.component.css']
})
export class CasMultiSelectEtiquetaComponent implements OnChanges, OnInit {
  public readonly BASE_TRANSLATE_SHARED = "shared";

  @Input('noElementsLabel') noElementsLabel: string;
  @Input('isbatch') isbatch: boolean;
  @Input('filteredElements') filteredElements: any[] = [];
  @Input('elements') elements: any[] = [];
  searchValue: string;
  states = [null, true, false];
  altstates = [null, false];
  loading: boolean;

  ngOnInit() {
    this.loading = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loading = false;
    const elements: SimpleChange = changes.elements;
    this.elements = elements.currentValue;
    this.elements.forEach(element => element.defaultstate = element.currentstate);
    this.filteredElements = this.elements.slice();
    this.sortArray();
  }

  sortArray() {
    this.filteredElements.sort((a, b) => {
      return (a.defaultstate != null ? (a.defaultstate === true ? 1 : -1) : 0) < (b.defaultstate != null ? (b.defaultstate === true ? 1 : -1) : 0)
        ? 1
        : (a.defaultstate != null ? (a.defaultstate === true ? 1 : -1) : 0) > (b.defaultstate != null ? (b.defaultstate === true ? 1 : -1) : 0)
          ? -1
          : a.descripcion > b.descripcion
            ? 1
            : a.descripcion < b.descripcion
              ? -1
              : 0;
    });
  }

  onChangeCheckbox(checked: boolean, id: number) {
    let current = this.elements.find(et => et.idetiqueta === id);
    let previouslychecked = this.elements.find(et => et.formulario != null && et.currentstate === true && et.currentstate != et.defaultstate);
    
    if (current.formulario == null || this.isbatch == false) {
      if (current.defaultstate === null) {
        current.currentstate = this.states[(this.states.indexOf(current.currentstate) + 1) % this.states.length];
      } else {
        current.currentstate = checked;
      }
    } else {
      if (current.defaultstate === null) {
        current.currentstate = this.altstates[(this.altstates.indexOf(current.currentstate) + 1) % this.altstates.length];
      } else if (current.defaultstate === false) {
        current.currentstate = false;
      }
      else {
        current.currentstate = checked;
      }
    }

    if(current.formulario != null && current.currentstate === true && current.currentstate != current.defaultstate && previouslychecked != null){
      previouslychecked.currentstate = false;
    }
  }

  searchElement(searchvalue: string) {
    if (searchvalue != null && searchvalue != '') {
      this.filteredElements = this.elements.filter(element =>
        element.descripcion.toLowerCase().indexOf(searchvalue.toLowerCase()) > -1).slice();
      this.sortArray();
    } else {
      this.filteredElements = this.elements.slice();
      this.sortArray();
    }
    return this.filteredElements.length;
  }
}

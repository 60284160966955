export class TableFieldSelect {
  public id: any;
  public label: string;
  public selected?: boolean;

  constructor(
    id: any,
    label: string,
    selected: boolean
  ) {
    this.id = id ? id : '';
    this.label = label ? label : '';
    this.selected = selected ? selected : false;
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'az-loader-button',
    templateUrl: './loader-button.component.html',
    styleUrls: ['./loader-button.component.css'],
    providers: []
})
export class LoaderButtonComponent implements OnInit {
    @Input("show-loading") isLoading: boolean = false;
    @Input("color") changeColor: string = "var(--secundario)";
    @Input("disabled") disabled: boolean = false;

    constructor() {}

    ngOnInit(): void {
        this.isLoading = this.isLoading ? this.isLoading : false;
        this.changeColor = this.changeColor ? this.changeColor : "var(--secundario)";
        this.disabled = this.disabled ? this.disabled : false;
    }
}
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { LoaderBaseComponent } from "./loader-base/loader-base.component";
import { LoaderButtonComponent } from "./loader-button/loader-button.component";
import { LoaderFullScreenComponent } from "./loader-fullscreen/loader-fullscreen.component";

@NgModule({
  declarations: [
    LoaderBaseComponent,
    LoaderButtonComponent,
    LoaderFullScreenComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule
  ],
  exports: [
    LoaderBaseComponent,
    LoaderButtonComponent,
    LoaderFullScreenComponent
  ],
})
export class AzLoadersModule {}

<div class="wrapper-title-app" mat-dialog-title>
    <button type="button" class="btn-app" (click)="CloseModal()"><span class="fas fa-arrow-left"></span></button>
    <p class="title-app">{{ subtitle }}: {{ title }}</p>
</div>
<mat-dialog-content>
    <div class="wrapper-chart-app">
        <div class="box-buscamina--filter-arrow">
            <div class="arrows">
                <span (click)="M_ANTERIOR()" class="btn-arrow change fas fa-angle-left"></span>
                <span (click)="fChange.split('T')[0] != fActual.split('T')[0] ? M_SIGUIENTE() : ''" class="btn-arrow fas fa-angle-right" [class]="fChange.split('T')[0] != fActual.split('T')[0] ? 'btn-arrow fas fa-angle-right change' : 'btn-arrow fas fa-angle-right'"></span>
            </div>
            <p class="fecha-arrow">{{ fChange === fActual ? formaterMesAnno(fActual) : formaterMesAnno(fChange) }}</p>

            <button class="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent download-button" (click)="S_MEDICIONES()"><i class="fas fa-search"></i> {{ BASE_TRANSLATE + '.text_search' | translate }}</button>
        </div>
        <div *ngIf="info?.length" class="box-buscamina">
            <div class="box-buscamina--header">
                <p class="item-title th">{{ BASE_TRANSLATE + '.text_beneficiario' | translate }}</p>
                <p class="item-title td" *ngFor="let u of info; let i = index" [routerLink]="['/programa/'+ idgrupo +'/detalle-beneficiario/' + u.iduser + '/' + 2]" (click)="CloseModal()">{{ u.username }}</p>
            </div>
            <div class="box-buscamina--data">
                <div class="th">
                    <span class="item text" *ngFor="let x of info[0].buscamina; let i = index">{{ i + 1 }}</span>
                </div>
                <div class="td" *ngFor="let u of info; let i = index">
                    <div class="item cuvo" *ngFor="let cm of u.buscamina; let i = index">
                        <span class="icon-done fas fa-check" [class]="cm.cumple === true ? 'show' : ''"></span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="loading" class="loading-buscamina">
            <az-loader-base [show-loading]="loading"></az-loader-base>
        </div>
    </div>
</mat-dialog-content>
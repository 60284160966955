import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPseudoCheckboxState } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mat-select-filter',
  templateUrl: './mat-select-filter.component.html'
})
export class MatSelectFilterComponent implements OnInit, OnChanges {
  @Input('MultiCtrl') public MultiCtrl: FormControl = new FormControl();
  public MultiFilterCtrl: FormControl = new FormControl();

  @Input('elements') elements: any[] = [];
  @Input('disabled') disabled: false;
  @Input('placeholderLabel') placeholderLabel: string;
  @Input('matLabel') matLabel: string;
  @Input('selectAllLabel') selectAllLabel: string;
  @Input('placeholderSearchLabel') placeholderSearchLabel: string;
  @Input('noEntriesFoundLabel') noEntriesFoundLabel: string;
  @Output() selectionChange = new EventEmitter();

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;
  public filteredMulti: ReplaySubject<any> = new ReplaySubject<any[]>(1);
  private _onDestroy = new Subject<void>();

  public state: MatPseudoCheckboxState = 'unchecked';
  private options = [];
  private value = [];
  private destroyed = new Subject();

  ngOnInit() {
    this.resetComponent();
    this.filteredMulti.next(this.elements.slice());
    this.MultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterMulti();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propiedad in changes) {
      if (propiedad === 'elements') {
        let change = changes[propiedad];
        let curVal = JSON.stringify(change.currentValue);
        let prevVal = JSON.stringify(change.previousValue);
        if (curVal !== prevVal)
          this.ngOnInit();
      }
    }
  }

  resetComponent() {
    this.MultiFilterCtrl = new FormControl();
    this.filteredMulti = new ReplaySubject<any[]>(1);
    this.options = [];
    this.value = [];
    this.state = 'unchecked';
  }

  ngAfterViewInit() {
    this.setInitialValue();
    if (typeof this.multiSelect.options != 'undefined') {
      this.options = this.multiSelect.options.map(x => x.value);
      this.multiSelect.options.changes.pipe(takeUntil(this.destroyed)).subscribe(res => {
        this.options = this.multiSelect.options.map(x => x.value);
        this.updateState();
      });

      this.value = this.multiSelect.ngControl.control.value;
      this.multiSelect.ngControl.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(res => {
        this.value = res;
        this.updateState();
      });
      this.updateState();
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredMulti.pipe(take(1), takeUntil(this._onDestroy)).subscribe(() => {
      this.multiSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
    });
  }

  protected filterMulti() {
    if (!this.elements) {
      return;
    }
    let search = this.MultiFilterCtrl.value;
    if (!search) {
      this.filteredMulti.next(this.elements.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredMulti.next(this.elements.filter(element => element.name.toLowerCase().indexOf(search) > -1));
  }

  onSelectAllClick(evt: MouseEvent) {
    if (this.state === 'checked') {
      this.multiSelect.ngControl.control.setValue([]);
    } else {
      this.multiSelect.ngControl.control.setValue(this.options);
    }
    this.selectionChange.emit({ value: this.multiSelect.ngControl.control.value });
  }

  onSelectionChange() {
    this.selectionChange.emit({ value: this.multiSelect.ngControl.control.value });
  }

  private updateState() {
    const areAllSelected = this.areArraysEqual(this.value, this.options);
    if (areAllSelected) {
      this.state = 'checked';
    } else if (this.value == null) {
      this.state = 'unchecked';
    } else if (this.value.length > 0) {
      this.state = 'indeterminate';
    } else {
      this.state = 'unchecked';
    }
  }

  private areArraysEqual(a, b) {
    return a && b ? [...a].sort().join(',') === [...b].sort().join(',') : false;
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TableAction } from '../../models/table-action';

@Component({
  selector: '[az-table-cell-actions]',
  templateUrl: './az-table-cell-actions.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AzTableCellActionsComponent {
  @Input() public actions: Array<TableAction> | null = null;
  @Input() public row: any | null = null;
  @Output() public actionClick = new EventEmitter<TableAction>();

  public actionClicked(event: any, action: TableAction): void {
    this.actionClick.emit(action);
  }

  public getClassesFromAction(action: TableAction): any {
    if (action.toggle) {
      if (this.row.item.active) {
        return action.icon;
      } else {
        return action.toggle;
      }
    }

    if (action.default) {
      if (this.row.item.default) {
        return action.icon;
      } else {
        return action.default;
      }
    }

    return action.icon;
  }

  public getTooltipFromAction(action: TableAction): any {
    if (action.default) {
      if (this.row.item.default) {
        return "Predeterminado";
      } else {
        return action.title;
      }
    }

    return action.title;
  }

  public getItemAction() {
    if (this.row) {
      if (this.row.item.actions) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  public getActionActive(action: TableAction | any): boolean {
    if (action.name) {
      if (this.row.item.actions[action.name.toLowerCase()]) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }

  public getValueBadge(): string {
    return this.row.item['badge'] > 99 ? '99+' : String(this.row.item['badge']);
  }

  public getActionBadge(action: TableAction): boolean {
    if (action.badge) {
      return action.badge;
    } else {
      return false;
    }
  }
}

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgEventBus } from 'ng-event-bus';
import { JsonResult } from 'src/app/models/json-result.model';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { ChatV2Service } from './chat.service';
import { SalaInterface } from './interfaces/sala.interface';
import Swal from 'sweetalert2';
import { SmsInterface } from './interfaces/sms.interface';

@Component({
  selector: 'app-chat-v2',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatV2Component implements OnInit {
  @ViewChild('chatmsg', { static: false }) chatmsg: ElementRef;
  @ViewChild('cananswertoggle', { static: false }) cananswertoggle: ElementRef;

  public readonly BASE_TRANSLATE = "chat";

  loading: boolean = false;

  ChatFrom: FormGroup;

  can_answer: boolean = false;
  username: string = '';
  ArrayMSG: any[] = [];
  cargaservice: boolean = false;
  cargaservicefull: boolean = false;

  lastPage: number = 1;
  totalItem: number = 0;
  page: number = 1;
  cantItemPage: number = 40;
  listMessage: any[] = [];

  sala: { id_sala: number, can_answer: boolean };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ChatV2Component>,
    private alert: SnackBarService,
    private chatService: ChatV2Service,
    private eventBus: NgEventBus
  ) {
    this.username = data.namepaciente;
  }

  ngOnInit() {
    this.ChatFrom = this.fb.group({
      msg: ['', [Validators.required]]
    });

    this.addAndGetSala();
  }

  scrollDown() {
    setTimeout(() => {
      this.changeScrolltop();
    }, 200);
  }
  changeScrolltop() {
    this.chatmsg.nativeElement.scrollTop = this.chatmsg.nativeElement.scrollHeight;
  }
  async getSMS() {
    this.chatService.getMessageSala(this.sala.id_sala, this.page, this.cantItemPage).subscribe((res: JsonResult) => {
      this.eventBus.cast(`sms:read`, { index_pac: this.data.index_pac });
      this.lastPage = res.retObject.last_page;
      this.totalItem = res.retObject.total;
      this.listMessage = res.retObject.data;
      this.orderSMS();
      this.scrollDown();
    }, err => {
      console.log(err);
    });
  }

  getMoreMessage() {
    this.cargaservice = true;
    this.page++;
    this.chatService.getMessageSala(this.sala.id_sala, this.page, this.cantItemPage).subscribe((res: JsonResult) => {
      this.totalItem = res.retObject.total;
      this.lastPage = res.retObject.last_page;
      res.retObject.data.forEach((item: any) => {
        this.listMessage.push(item);
      });

      this.orderSMS();
      this.cargaservice = false;
    }, err => {
      this.page--;
      console.log(err);
      this.cargaservice = false;
    });
  }

  sendSMS() {
    let data: SmsInterface = {
      id_sala: this.sala.id_sala,
      id_type_sms: 1,
      sms_chat: this.ChatFrom.value.msg
    };
    this.ChatFrom.get("msg").setValue("");

    this.chatService.postMessageSala(data).subscribe((res: JsonResult) => {
      this.listMessage.push({
        ...res.retObject,
      });
      this.orderSMS();
      this.scrollDown();
    }, err => {
      console.log(err);
      this.alert.error(`Error inesperado, comuniquese con el administrador del sistema.`);
    });
  }

  orderSMS() {
    this.listMessage.sort((a: any, b: any) => {
      if (a.id_chat < b.id_chat) {
        return -1;
      }
    });

  }

  addAndGetSala() {
    this.loading = true;
    let data: SalaInterface = {
      iduser_pac: this.data.idpaciente
    };

    this.chatService.postAddAndGetSala(data).subscribe((res: JsonResult) => {
      if (res.retCode === '0') {
        this.sala = res.retObject;
        this.can_answer = this.sala.can_answer;
      } else {
        this.alert.warn(res.retMessage);
      }
      this.getSMS();
      this.loading = false;
    }, err => {
      console.log(err);
      this.alert.error(`Error inesperado, comuniquese con el administrador del sistema.`);
      this.loading = false;
    });
  }

  canAnswer() {
    Swal.fire({
      title: `¿Estás segundo de ${this.can_answer ? 'desactivar' : 'activar'} que el beneficiario responda los mesajes?`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.cargaservicefull = true;
        this.chatService.putCanAnswerBeneficiary({ id_sala: this.sala.id_sala }).subscribe((res: JsonResult) => {
          if (res.retCode === '0') {
            this.can_answer = !this.can_answer;
            this.sala.can_answer = this.can_answer;
            if (this.can_answer) {
              Swal.fire('Ahora el beneficiario podrá responderte los mensajes.', '', 'success');
            } else {
              Swal.fire('Ahora el beneficiario no podrá responderte los mensajes.', '', 'success');
            }
          } else {
            this.alert.warn(res.retMessage);
          }
          this.cargaservicefull = false;
        }, err => {
          console.log(err);
          this.alert.error(`Error inesperado, comuniquese con el administrador del sistema.`);
          this.cargaservicefull = false;
        });
      }
    })
  }

  closeModalDialog(): void {
    this.dialogRef.close();
  }
}
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NgEventBus } from 'ng-event-bus';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ProfileService } from 'src/app/shared/services/profile.service';

@Component({
    selector: 'app-cropper-photo',
    templateUrl: './cropper-photo.component.html',
    styleUrls: ['./cropper-photo.component.css'],
    providers: []
})
export class CropperPhotoComponent implements OnInit {
    public readonly BASE_TRANSLATE = "profile";
    public readonly BASE_TRANSLATE_SHARED = "shared";

    cargaservice: boolean = false;
    loading: boolean = false;

    is_paciente: boolean = false;

    listSex: any[] = [];

    imageChangedEvent: any = '';
    croppedImage: any = '';

    image: any = "assets/img/avatar.png";

    containWithinAspectRatio = false;
    canvasRotation = 0;
    transform: ImageTransform = {};
    showCropper: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<CropperPhotoComponent>,
        private translate: TranslateService,
        private snackBar: MatSnackBar,
        private profileService: ProfileService,
        private eventBus: NgEventBus
    ) { }

    ngOnInit() {
        // code
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        this.showCropper = true;
    }

    submit() {
        this.cargaservice = true;
        let data: any = { img: this.croppedImage };

        this.profileService.putAvatar(data).subscribe((res: any) => {
            if (res.retCode === '0') {
                this.snackBar.open(this.translate.instant(this.BASE_TRANSLATE + '.message.sms_1'), '', { panelClass: 'success', duration: 4500 });
                this.eventBus.cast("avatar:user", { avatar: this.croppedImage });
                this.dialogRef.close(true);
            } else {
                this.snackBar.open(res.retMessage, '', { panelClass: 'warn', duration: 4500 });
            }
            this.cargaservice = false;
        }, (err) => {
            console.log(err);
            this.snackBar.open(this.translate.instant(this.BASE_TRANSLATE + '.message.sms_2'), '', { panelClass: 'error', duration: 4500 });
            this.cargaservice = false;
        });
    }
}
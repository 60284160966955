import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { JsonResult } from "src/app/models/json-result.model";
import { GrupoServices } from "src/app/services/grupo.service";

@Component({
    selector: 'az-adherencia-general',
    templateUrl: './adherencia-general.component.html'
})
export class AzAdherenciaGeneralComponent implements OnInit {
    public readonly BASE_TRANSLATE = "dashboard";

    @Input() idprogram: number = 0;
    @Input() idPaciente: number | undefined = undefined;
    info: any = {
        metrica: "",
        general: 0,
        esperada: 0
    };

    loading: boolean = false;
    loading_metrica: boolean = false;

    constructor(
        private grupoService: GrupoServices,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        if (this.idPaciente != undefined) {
            this.getMetricaGrupo();
        }
        this.getAdherenciaGeneral();
    }

    getMetricaGrupo() {
        this.loading_metrica = true;
        this.grupoService.getMetricaGroup(this.idprogram).subscribe((trae: JsonResult) => {
            this.info.metrica = trae.retObject.despliegue === null ? "" : trae.retObject.despliegue;
            this.loading_metrica = false;
        }, (error: any) => {
            console.log(error);
            this.loading_metrica = false;
        });
    }

    getAdherenciaGeneral() {
        this.loading = true;
        let servicio: any = this.idPaciente === undefined ? this.grupoService.getAdherenciaGeneral(this.idprogram) : this.grupoService.getAdherenciaGeneralPorPaciente(this.idprogram, this.idPaciente);
        servicio.subscribe((trae: JsonResult) => {
            this.info.general = trae.retObject.general === null ? 0 : trae.retObject.general;
            this.info.esperada = trae.retObject.esperada === null ? 0 : trae.retObject.esperada;

            if (this.info.general >= 100) {
                this.info.general = 100;
            }

            if (this.info.esperada >= 100) {
                this.info.esperada = 100;
            }

            this.loading = false;
        }, (error: any) => {
            console.log(error);
            this.loading = false;
        });
    }

    getClassColor() {
        if (this.info.general < 50) {
            return 'c-49';
        } else if (this.info.general >= 50 && this.info.general < 75) {
            return 'c-50-75';
        } else {
            return 'c-76';
        }
    }

    getTitle(): string {
        return this.translate.instant(`${this.BASE_TRANSLATE}.widget_cars_programas.text_adherencia_general`);
    }
}